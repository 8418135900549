function closePopup(popup){
    popup.fadeOut();
}

function openPopup(popup){
    popup.fadeIn(200,function(){
        $('.popup_content .selectboxit-options').mCustomScrollbar({
            set_height: 225,
            set_width: 186
        });
    });
}

if($('.feedback-popup').length > 0){
    openPopup($('.feedback-popup'));
}

$("body").on("click", ".feedback_popup_link", function(e) {

	e.preventDefault();

     $.post("/ajx/ajax.file.php", {fn:'geFeedbackPopup' }, function(data){
        $("#feeback_popup").html(data);
        openPopup($('.feedback-popup'));
        main_stars();
     });
});

/* Brand Rating/Review Submit */

$('body').on('click', '.brand_rating_comment_submit', function(){

    var comment_cont = $(this).prev('.brand_rating_comment');
    var rating_popup = $('.rating-comment-popup');

    var id = comment_cont.attr('data-id');
    var comment = comment_cont.val();
    var token = rating_popup.find('input[name="csrf_token"]').val();

    if(comment.length == 0) {
        comment_cont.addClass('error');
        return false;
    }

    $.post("/ajx/ajax.file.php", {fn:'addCommentForBrandRating', id: id, comment: comment, 'csrf_token' : token}, function(data){
        rating_popup.find('form').fadeOut(function(){
            rating_popup.find('.success').fadeIn();
            setTimeout(function(){ rating_popup.find('.close-popup').trigger('click'); }, 5000);
        });
    }, "json");
});

$('body').on('click', '#feedback_comment_submit', function(){
    var comment = $('#feedback_comment').val();
    var token = $('.feedback-popup input[name="csrf_token"]').val();
    var vote = $('.feedback-popup .star.active').attr('data-star');
    var via_ajax = $('.feedback-popup input[name="via_ajax"]').val();

    if(comment.length == 0 || comment.length == 1) {
        $('#feedback_comment').addClass('error');
        return false;
    }

    $.post("/ajx/ajax.file.php", {fn:'addFeedbackComment', comment: comment, vote: vote, 'csrf_token' : token, 'via_ajax': via_ajax}, function(data){
        $('.feedback-popup').find('form').fadeOut(function(){
            $('.feedback-popup').find('.success').fadeIn();
            setTimeout(function(){ $('.feedback-popup .close-popup').trigger('click'); }, 5000);
        });
    }, "json");
});

$('body').on('click', '.close-popup', function(e){
    popup = $(this).closest('.product_popup');
    closePopup(popup);
});

$('body').on('click', '.popup_overlay', function(e){
    popup = $(this).closest('.product_popup');
    closePopup(popup);
});

function main_stars() {
    
    if ($( ".rating" ).hasClass( "js_rating_inited" )) {
	return true;
    }else{
	$( ".rating" ).addClass( "js_rating_inited" );
    }
    
    $widgetRating = $('.rating');
    if($widgetRating.length != 0){

        /*Voting Mechanics - Vote Now*/
        $stars = $('.stars').not(".set");

        $stars
        .mouseover(
            function(event){
                $this = $(event.target).parent();
                $star = $this.find('.star');
                $target = $(event.target);
                $wrapper = $target.parents('.rating');
                $vote = $wrapper.find('.user_vote span');

                if($target.is('.star')){

                    $star.removeClass('hover');

                    var rating = $target.data('star');
                    $vote.text(rating);

                    $.each($star, function(index, element) {
                        if(index >= rating){ return; }
                        $(element).addClass('hover');
                    });
                }
        })
        .mouseleave(function(event){
            $star.removeClass('hover');
            $vote.text("-");
        })
        .on('click', '.star', function(event){
	    
            var vote_value = $(event.target).data('star');

            if(!$(event.target).parents('.rating').hasClass('feedback_rating')){

                var this_id = $(event.target).parents('.rating').data('id');
                var obj = $(event.target).parents('.rating').data('obj');

                $.ajax({
                    context: $("#ID" + this_id),
                    url: "//"+ domain +"/ajx/ajax.file.php",
                    dataType: 'jsonp',
                    crossDomain: "true",
                    data: { fn: "updateRating", vote: vote_value, id: this_id, obj: obj }
                }).done(function(data){

                    $wrapper.find('.current_result span').text(data.average);
                    var text = $wrapper.find('.current_result em[itemprop="votes"]').text();
                    $wrapper.find('.current_result em[itemprop="votes"]').text(parseFloat(text)+1);
                    $wrapper.parent().find("#rating-value-text").text(data.average);

                    if(obj == 'brand'){
                        /*$wrapper.parent().find*/$('.rating-comment-popup').find('textarea').attr('data-id', data.id);
                        /*$wrapper.parent().find*/$('.rating-comment-popup').fadeIn();
                    }
                });
            }else{
                $(event.target).addClass('active');
            }
            
            if(typeof $this !== 'undefined'){
                $this.off("mouseleave").off("mouseover").off('click');
            }

        });

        /*Voting Mechanics - If Was Voted (Have Coockie) */
        $stars_off = $('.stars.set');

        $.each($stars_off, function(index, element) {
            $active = $(element).find('.active');
            var value = $active.data('star');
            $star = $(element).find(".star");
            $.each($star, function(index, element) {
                if(index>=value){ return; }
                $(element).addClass('hover');
            });
        });
    }/*WIDTGET-MALLS END*/

}
/*MAIN*/

/* Brand Card Rating - Activate */
$card = $(".business-card-rating");
if ($card.length!==0) {
    main_stars();
};



