window.cookiesFunc = Cookies;

jQuery.extend(jQuery.fn, {
    // Name of our method & one argument (the parent selector)
    within: function(pSelector) {
        // Returns a subset of items using jQuery.filter
        return this.filter(function() {
            // Return truthy/falsey based on presence in parent
            return $(this).closest(pSelector).length;
        });
    },
    // Name of our method & one argument (the parent selector)
    isWithin: function(pSelector) {
        return $(this).closest(pSelector).length;
    }
});

$('.first-type .small-iwant-btn, .subscribe-view:not(.popup-form) .yes-iwant-btn, .second-type.mobile .yes-iwant-btn, .subscribe-view-small .yes-iwant-btn, .subscribe-view-mobile .yes-iwant-btn').magnificPopup({
    type: 'inline',
    fixedContentPos: true,
    midClick: true, // Allow opening popup on middle mouse click. Always set it to true if you don't provide alternative source in href.
});

function toggleTooltip() {
    $('#gdpr-tooltip').toggle();
}

function popupDelay() {
    $('.brand-popup').magnificPopup({
        type: 'inline',
        midClick: true,
        callbacks: {
            open: function() {
                if (Cookies.get('subscribe.modal.v' + $('#brand-popup').data('version'))) {
                    $('.mfp-close').trigger('click');
                }
            },
            close: function() {
                Cookies.set('subscribe.modal.v' + $('#brand-popup').data('version'), 1, { expires: 365 });
            }
        }
    });
    $('.newsletter-popup').magnificPopup({
        type: 'inline',
        midClick: true,
        callbacks: {
            open: function() {
                $('.mfp-content').css('max-width', 'none'); //Fix for cashback and profile popups
                if (window.cookiesFunc.get('subscribe.modal.v' + $('#newsletter-popup').data('version'))) {
                    $('.mfp-close').trigger('click');
                }
            },
            close: function() {
                $('.mfp-content').css('max-width', '520px'); //Fix for cashback and profile popups
                window.cookiesFunc.set('subscribe.modal.v' + $('#newsletter-popup').data('version'), 1, { expires: 365 });
            }
        }
    })
}

function seedEventTag(formData) {
    var tag = {},
        result = {};

    $.each(formData, function(key, value) {

        if (value.name === "selectCityName") {
            tag.city = value.value;
        }

        if (value.name === "brandName") {
            tag.brand = value.value;
        }

        if (value.name === "type") {
            tag.type = value.value;
        }

    });

    if (["brand", "brand-popup", "mobile-brand-popup", "brand:mobile", "view-front-cover", "view-back-cover"].indexOf(tag.type) > -1) {
        tag.label = tag.brand + '-' + tag.city;
    } else {
        tag.label = tag.city || 'General';
    }

    result = {
        category: (tag.type == 'view-front-cover' || tag.type == 'view-back-cover' ? "Newssletter:" : "Newsletter:") + tag.type,
        label: tag.label
    };


    if (typeof page_name !== 'undefined' && page_name == "brand_city" && tag.type === "brand-popup") {
        result.category = "newsletter:brand-city-popup";
    }

    return result;

}

/* Subscribe Test */
function testEvents(tag) {
    $.ajax({
        type: "POST",
        url: "/ajx/ajax.file.php",
        data: { fn: "testEvents", ga: ["send", "event", tag.category, tag.label] }
    });
}

function radioErrorText(Field) {
    if (Field.element.type == 'radio') {
        var $el = $(Field.element),
            $form = $el.closest('form'),
            $errPlaceHolder = $form.find('.sex-field-wrapper');

        return $errPlaceHolder;
    }
    if (Field.element.name == 'city') {
        var $ecit = $(Field.element),
            $formCit = $ecit.closest('form'),
            $errPlaceHoldCit = $formCit.find('.city-field');

        return $errPlaceHoldCit;
    }
}

function radioErrorClass(Field) {
    if (Field.element.type == 'radio') {
        var $e = $(Field.element),
            $fo = $e.closest('form'),
            $errPlaceHold = $fo.find('.sex-field-wrapper .sex-field');
        // $form.find('.sex-field').addClass("parsley-error");

        return $errPlaceHold;
    }

    //Add parsley-error class to .select-placeholder
    if (Field.element.name == 'city') {
        var $ecity = $(Field.element),
            $formCity = $ecity.closest('form'),
            $errPlaceHoldCity = $formCity.find('.select-placeholder');

        return $errPlaceHoldCity;
    }
}

if (page_name !== 'undefined' && page_name !== 'home') {
    if ($('.brand-popup').length) {
        $(document).on('mouseleave', function(e) {
            if (window.location.hash == "" && !$('input').is(':focus')) {
                if ($('.mfp-wrap:visible').length == 0) {
                    $('.brand-popup').trigger('click');
                }
            }
        });
    } else if ($('.newsletter-popup').length) {
        $(document).on('mouseleave', function(e) {
            if (window.location.hash == "" && !$('input').is(':focus')) {
                if ($('.mfp-wrap:visible').length == 0) {
                    $('.newsletter-popup').trigger('click');
                }
            }
        });
    }
}

function hideUnnecessaryEmailError() {
    if ($('input[name=subscriber]').siblings('.subscriber-error').is(':visible')) {
        $('input[name=subscriber]').next().find('.parsley-custom-error-message').hide();
    }
}

function hideForm($form) {
    var mfp = $('.mfp-content').length ? '.mfp-content ' : '';
    if ($form && $form.closest('.mail-field').length > 0) {
        var $parent = $form.closest('form');
        $parent.find(mfp + ".yes-iwant-btn").css('visibility','hidden');
        $parent.find(mfp + ".yes-iwant-btn").css('height','0');
        $parent.find(mfp + ".underline").fadeOut(200);
    } else {
        $(mfp + ".yes-iwant-btn").css('visibility','hidden');
        $(mfp + ".yes-iwant-btn").css('height','0');
        $(mfp + ".subscribe-loader").fadeIn(200);
    }
}

function setSubscribeTypeahead(el) {

    var $ttSub = $(el + ' input.typeahead-subscribe'),
        formSel = el;

    var path = current_path ? current_path : '';

    $ttSub.typeahead2({
        name: 'cities',
        limit: 8,
        local: typeaheadCities,
        remote: template.typeahead.domain + "/main/citysearch/%QUERY",
        template: '<span class="tt-link" data-id="{{id}}">{{value}}</span>',
        engine: Hogan
    });

    $ttSub.on("blur", function(e) {
        setTimeout(function() {
            $(this).closest(formSel).find('.tt-suggestion').remove();
        }, 400);
    });

    $ttSub.on("typeahead:selected", function(e, city) {
        $(this).closest('form').find('#selectCityName').val(city.value);
        $(this).closest('form').find('.select-placeholder').text(city.value);
        $(this).closest('form').find('.select-value').val(city.id);
    });

    $ttSub.on('typeahead:opened', function() {
        var suggestionsData = $(this).data();

        suggestionsData.ttView._clearHint();
    });

    $ttSub.on("typeahead:initialized ", function() {
        var noHrefs = $(this).data('no-hrefs');

        var $ttSubDropDown = $(this).parent().find('.tt-dropdown-menu');

        var suggestionsData = $(this).data();

        translateTypeaheadCities();

        suggestionsData.ttView.dropdownView.renderSuggestions(suggestionsData.ttView.datasets[0], translatedSArray);

        $ttSubDropDown.mCustomScrollbar();
    });

    var ttTimeout;
    $ttSub.on('keyup', function() {
        var value = this.value,
            t,
            noHrefs = $(this).data('noHrefs'),
            $ttSubDropDown = $(this).parent().find('.tt-dropdown-menu');

        ifInputEmptyReset(value, $ttSubDropDown, noHrefs);

        clearTimeout(ttTimeout);
        ttTimeout = setTimeout(function() {
            ifInputFullAndNoResults(value, $ttSubDropDown);
        }, 500);
    });
}

$(document).ready(function() {

    function _openSubscribeSuccessPopup(){
        var _popupSel = '#subscribe-success',
            _popupMainClass = 'mfp-subscribe-success';

        $.magnificPopup.open({
            mainClass: _popupMainClass,
            items: {
                src: _popupSel,
                type: 'inline'
            },
            callbacks: {
                open: function(){
                    document.querySelector('html').classList.add('html-block-scroll');
                },
                close: function(){
                    document.querySelector('html').classList.remove('html-block-scroll');
                }
            }
        });
    }

    setTimeout(function() {
        if ($("#foxpush_box").css('display') == 'block') {
            setTimeout(function() {
                $("#foxpush_box").remove();
                popupDelay();
            }, 20000);
        } else {
            popupDelay();
        }
    }, 1500)
    setTimeout(function() { $(window).trigger('hashchange'); }, 900);

    $("body").on("click", ".subscribe-refresh", function() {
        $(this).remove();
        $('.yes-iwant-btn').css({'visibility':'visible', 'height':'45px'});
        $('.subscribe-error').remove();
    });

    $("body").on("click", "#subscribed-popup .button", function() {
        ga('send', 'event', { eventCategory: 'Email-Subscribe-Funnel', eventAction: 'Email-Sent-Pop-Up', eventLabel: 'Click-OK' });
        $.magnificPopup.close();
    });


    $("form.subscribe-brand_new").parsley({
        classHandler: radioErrorClass,
        errorsContainer: radioErrorText
    });

    $(document).on('click', '#gdpr-tooltip', function() {
        $('#gdpr-tooltip').remove();
    });

    $(document).on('click', '#permission-pass .modal-iwant', function(e) {
        e.preventDefault();
        var email = $('form#gdpr-confirm input').val();
         $.ajax({
            url: '/ajx/ajax.file.php',
            method: 'POST',
            data: {fn: 'permissionPopupConfirm', email: email},
            dataType: 'json',
            success: function(data) {
                if (data.status) {
                    $('#permission-pass .links a').hide();
                    $('#permission-pass .links .confirm-success').show();
                    setTimeout(function(){
						$.magnificPopup.close();
                    }, 3000);
                }
                else {
                    $('#permission-pass .links a').hide();
                    $('#permission-pass .links .confirm-error').show();
                }
            },
            error: function(err) {
                console.log(err);
            }
        });
    });

    $(document).on('click', '.yes-iwant-btn', function() {
        hideUnnecessaryEmailError();
    });

    $(document).on('blur', 'input[name=subscriber]', function() {
        hideUnnecessaryEmailError();
    });

    $(document).on("click", "body", function(e) {
        if (!$(e.target).is('.info-btn') || $(".info-btn").has(e.target).length !== 0) {
            $('#gdpr-tooltip').hide();
        }
    });

    $(document).on("click", "#permission-pass p.unsubscribe-text a", function(e) {
        ga('send', 'event', 'Permission-Pass-popup', 'To-Profile');
    });

    if (typeof isMobile == 'undefined' || !isMobile) {
        $("input").not(".spring-game input").iCheck({
            checkboxClass: 'icheckbox_minimal-blue',
            radioClass: 'iradio_minimal-blue',
            labelHover: false,
            cursor: true
        });
    }

    if (page_name == 'brochure_view' && typeof isMobile === 'undefined') {
        $(document).on('click', '.select-city:not(.select-hidden-mode)', function(e) {
            $this = $(this);

            if (!$(e.target).is($this.find('.select-placeholder')) && !$(e.target).is($this.find('.select-placeholder span')) && !$(e.target).is($this.find('.icon-select-down'))) {
                return;
            }

            if ($this.hasClass("select-open")) {
                $this.removeClass('select-open');
            } else {
                $this.addClass('select-open');
                $this.find(".select-search input").focus();
            }
        });

        $('body').on('click', function(e) {
            if (!$(e.target).isWithin(".select-city.select-hidden-mode")) {
                $(".select-hidden-mode").find('.select-options').addClass('empty');
                $(".select-hidden-mode").find('.mCSB_container').find('.select-option').hide();
                $(".select-hidden-mode").find('input').val("");
            }
        });


        $(document).on('click', ".select-option:not(.select-hidden-mode)", function(e) {
            var $option = $(this);
            var $input = $option.parents(".select").find('input[type=hidden]');
            var $input2 = $option.parents(".select").find('input#selectCityName');

            if ($input.length === 0) {
                $input = $option.parents(".select").find('.select-value');
            }

            var $placeholder = $option.parents(".select").find('.select-placeholder');

            if ($input2.length !== 0) {
                $input2.val($option.text().replace(/\r?\n|\r/g, '').replace(/^\s\s*/, '').replace(/\s\s*$/, ''));
            }

            $input.val($option.data("value"));

            $placeholder.html('<span>' + $option.text() + '</span><ins class="icon-select-down"></ins>');

            $option.parents(".select").not('.select-hidden-mode').removeClass('select-open');

            if ($option.isWithin('.select-address')) {
                $("#submit-address").submit();
            }
        });

        $(".select-options")
            .not('.select-hidden-mode .select-options')
            .not('.select-typeahead .select-options')
            .mCustomScrollbar({

                callbacks: {
                    onInit: function() {}
                }
            });

        $("body").on("click", function(event) {
            if (!$(event.target).isWithin(".select-city")) {
                $(".select-city").not('.select-hidden-mode').removeClass('select-open');
            };
        });
    }

    $(document).on("submit", ".subscribe-brand_new", function(e) {
        e.preventDefault();

        var $form = $(this);
        var isValidEmail = $form.find('[name=subscriber]').attr('valid') == "true";
        var $submitButton = $form.find('[type=submit]');

        if (typeof isMobile == 'undefined' && page_name == 'brochure_view') {
            $form.find('input[name="type"]').val($('#cover-front').hasClass('cover-hidden') && $('#cover-back').hasClass('fadein') ? 'view-back-cover' : 'view-front-cover');
        }

        $submitButton.data("pending-submit", true);

        if (isValidEmail != true) {
            return;
        }

        $submitButton.attr("disabled", true);
        $submitButton.data("pending-submit", false);

        var data = $form.serialize();
        var dataArray = $form.serializeArray();

        hideForm();


        $.ajax({
            url: '/ajx/ajax.file.php',
            method: 'POST',
            data: data,
            beforeSend: function(formData) {

                window.gaEventTags = seedEventTag(dataArray);

            },
            success: function(r) {
                response = JSON.parse(r);

                $('.subscribe-success').remove();
                $('.subscribe-error').remove();

                setTimeout(function() {
                    $(".subscribe-loader").fadeOut(200);
                    $submitButton.removeAttr("disabled");

                    if (response.status) {
                        if ($('.mfp-content .white-popup').length && page_name != 'brochure_view') {
                            window.cookiesFunc.set('subscribe.modal.v' + $('.mfp-content .white-popup').data('version'), 1, { expires: 365 });
                        }
                        if (!$('.subscribe-success').length) {
                            var br = (page_name == 'brochure_view') ? '<br>' : '';
                            $("<div class='subscribe-success'>" + response.msg + "</div>" + br).insertBefore($('.mfp-content').length ? ".mfp-content .underline" : ".underline");
                        }
                        
                        $(".subscribe-loader").hide();
                        var tag = window.gaEventTags;
                        var popup = (isMobile === false && $('.mfp-content .white-popup').length && page_name == 'brand_city') ? '_popup' : '';
                        tag.category = ga_events.subscribe[page_name + popup] || tag.category;

                                var campaign_subscribe = $("h1.title-campaign").text();
                                if($(".campaigns .campaign-wrapper").length > 0 && !$("#newsletter-popup").is(":visible")){
                                    if(typeof ga !== 'undefined'){
                                        ga('send', 'event', 'Newsletter:campaign-landing-desktop', campaign_subscribe);
                                    }
                                }
                                else if($(".popup-campaign").length > 0 && $(".campaign-wrapper").length > 0){
                                    if(typeof ga !== 'undefined'){
                                        ga('send', 'event', 'Newsletter:campaign-landing-mobile', campaign_subscribe);
                                    }
                                }
                                if($(".campaigns .campaign-wrapper").length > 0 && $("#newsletter-popup").is(":visible")){
                                    if(typeof ga !== 'undefined'){
                                        ga('send', 'event', 'Newsletter:campaign-landing-pop-up', campaign_subscribe);
                                    }
                                }
                                else{
                                testEvents(tag); // Test and Count
                                    ga('send', 'event', tag.category, tag.label);
                                }
                                
                                if ($(".small-iwant-btn").length) {
                                    $(".small-iwant-btn").show();
                                }

                                $('#subscribe-success .subscribe-success__text').html(response.above_btn_txt);
                                $('#subscribe-success a.subscribe-succcess__brand-btn-close_gray').attr('href', response.unsubscribe_link);
                                //$('.mfp-close').trigger('click');
                                _openSubscribeSuccessPopup();

                                //Remove subscribe-related sections after successful subscribe
                                $('.vr-subscribe-btn-wrap, .second-type,.brandc-subscribe,.subscribe-section,#brand-popup,#newsletter-popup,a.brand-popup,a.newsletter-popup,#subscribebrand,#cam_popup-open').remove();
                                $('form.cam_form').parent().remove();
                                return false;

                    }else{
                        if (!$('.subscribe-error').length) {
                            $($('.mfp-content').length ? ".mfp-content .subscribe-brand_new" : ".subscribe-brand_new").append("<div class='subscribe-error'>" + response.msg + "<div class='button button-primary subscribe-refresh ml10'>"+ window.subscribe_btn +"</div></div>");
                        }
                        
                        $(".subscribe-loader").hide();
                    }
                }, 200);
            }
        });
    });

    setSubscribeTypeahead('.subscribe-brand_new');

    //campaign events
    $("body").on("click", ".wallp a", function(){
        var arr_wallpaper = $(this).attr("href").split("/");
        var camEventLabel = arr_wallpaper[arr_wallpaper.length-1].replace(/-/g, " ");
        if(typeof ga !== 'undefined' && typeof arr_wallpaper !== 'undefined' && arr_wallpaper !== ''){
            ga('send', 'event', 'Wallpaper', "desktop-"+page_name, camEventLabel);
        }
    });

    //desktop slider
    $("body").on("click", ".hero_section .hero_slide.active a, .hero_section .hero_slide.active .hero_catalogs .catalog-title a, .hero_section .hero_slide.active .hero_catalogs .catalog-cover a", function(){
        var arr_theme = $(".hero_section .hero_slide.active a").attr("href").split("/");
        var themeName = arr_theme[arr_theme.length-1].replace(/-/g, " ");
        var eventAction="";
        switch($(this)[0]){
            case $(".hero_section .hero_slide.active a")[0]:
                    eventAction = "desktop-" + page_name + "-theme-picture";
                    if(typeof ga !== 'undefined' && typeof arr_theme !== 'undefined' && arr_theme !== ''){
                        ga('send', 'event', 'Theme-Page-Slider', eventAction, themeName);
                    }
                break;
            case $(".hero_section .hero_slide.active .hero_catalogs .catalog-title a")[0]:
                    eventAction = "desktop-" + page_name + "-"+themeName + "-theme-brochure-name";
                    var arr_link = $(this).attr("href").split("/");
                    var linkName = arr_link[arr_link.length-1].replace(/-/g, " ");
                    if(typeof ga !== 'undefined' && typeof arr_theme !== 'undefined' && arr_theme !== ''){
                        ga('send', 'event', 'Theme-Page-Slider', eventAction, linkName);
                    }
                break;
            case $(".hero_section .hero_slide.active .hero_catalogs .catalog-cover a")[0]:
                    eventAction = "desktop-" + page_name + "-"+themeName + "-theme-brochure-cover";
                    var arr_link = $(this).attr("href").split("/");
                    var linkName = arr_link[arr_link.length-1].replace(/-/g, " ");
                    if(typeof ga !== 'undefined' && typeof arr_theme !== 'undefined' && arr_theme !== ''){
                        ga('send', 'event', 'Theme-Page-Slider', eventAction, linkName);
                    }
                break;
        }
    });

    //mobile slider
    $("body").on("click", ".content-theams .swiper-slide a", function(){
        var arr_theme = $(this).attr("href").split("/");
        var camEventLabel = arr_theme[arr_theme.length-1].replace(/-/g, " ");
        if(typeof ga !== 'undefined' && $(".mobile-device").length > 0 && typeof arr_theme !== 'undefined' && arr_theme !== ''){
            ga('send', 'event', 'Theme-Page-Slider', "mobile-"+page_name+"-picture", camEventLabel);
        }
    });
    $("body").on("click", ".header-categories .dropdown .dropdown-content a.category-img-url", function(){
        var arr_menu = $(this).attr("href").split("/");
        var camEventLabel = arr_menu[arr_menu.length-1].replace(/-/g, " ");
        if(typeof ga !== 'undefined' && typeof arr_menu !== 'undefined' && arr_menu !== ''){
            ga('send', 'event', 'Categories-Dropdown', page_name + "-picture", camEventLabel);
        }
    });

    //city primotions
    $("body").on("click", "section.row .banner a, section.row .banner.banner-tiny a, .promotions-slider .swiper-slide a, .promotions-slider .swiper-slide.small a", function(){
            var name = $(this).attr("title");
            var promoType;
            if(typeof ga !== 'undefined' && $(".mobile-device").length > 0 && typeof name !== 'undefined' && name !== ''){
                if($(this).closest(".swiper-slide.small").length == 1){
                    promoType = "-small";
                }else{
                    promoType = "-big";
                }             
                ga('send', 'event', 'Promotions', "mobile-" + page_name + promoType, name);
            }
            if(typeof ga !== 'undefined' && $(".mobile-device").length == 0 && typeof name !== 'undefined' && name !== ''){           
                if($(this).closest(".banner.banner-tiny").length == 1){
                    promoType = "-small";
                }else{
                    promoType = "-big";
                }
                ga('send', 'event', 'Promotions', "desktop-" + page_name + promoType, name);
           }
    });

});
