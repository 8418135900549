var readMoreObj = {
    priority: 5,
    method: function() {
        var isMobileInner = typeof isMobile !== 'undefined' && isMobile,
            isTabletInner = typeof isTablet !== 'undefined' && isTablet,
            isRedesign = $('.brandc-header').length > 0;

        if (typeof page_name !== 'undefined' && page_name == "brand_city") {
            var setClickEvent = false,
                infoContainerSel = isRedesign ? '.brandc-brochure-item__right-top' : (isMobileInner ? '.catalog-info' : '.brochure-info'),
                descContainerSel = isRedesign ? '.brandc-catalog-description' : (isMobileInner ? '.catalog-description' : 'div.subtitle');


            $(infoContainerSel).each(function(inx, item) {
                var $parent = $(this),
                    $subtitle = $parent.find(descContainerSel);

                if ($subtitle.length && $subtitle[0].scrollHeight > $subtitle.outerHeight(true)) {
                    $subtitle.next('.read-more').show();
                    setClickEvent = true;
                }
            });

            if (setClickEvent) {
                $(document).on('click', infoContainerSel + ' .read-more', function(e) {
                    e.preventDefault();
                    
                    var $parent = $(this).closest(infoContainerSel),
                        $subtitle = $parent.find(descContainerSel),
                        h = $subtitle[0].scrollHeight;

                    if ($subtitle.hasClass('view-more')) {
                        var blueCursorH =  $subtitle.find('.blue-cursor').outerHeight(true);
                        
                        blueCursorH = isMobileInner ? (isTabletInner ? 16 : 13) : 18;
                        h = blueCursorH * (isMobileInner ? (isTabletInner ? 7 : 4) : 5);
                    }

                    h = isRedesign ? '100%' : h + 'px';

                    $subtitle.stop().animate({
                        'height': h
                    }, 0, function() {
                        $subtitle.toggleClass('view-more');
                    });
                });
            }
        }
    }
};

readyQueue.methodQueue.push(readMoreObj);