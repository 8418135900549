// Add Styled Inputs
/*
$("input").not(".spring-game input").iCheck({
	checkboxClass: 'icheckbox_minimal-blue',
    radioClass: 'iradio_minimal-blue',
    labelHover: false,
  	cursor: true
});
*/

$('input').on('ifChanged', function(event){

  /* Subscribe Form - Validate - Checkbox */
  if ($(".subscribe-form").length!==0) {
  	// $(".subscribe-form").valid();
  };

  /* Products Filters - Checkbox */
  if ($("#products_categories").length!==0) {

  	if ($(this).isWithin("#products_categories")) {
  		$("#products_categories").submit();
  	};

  };

  /* Search Results Page Filters - Checkbox */
  if ($("#search_filters").length!==0) {

    if ($(this).isWithin("#search_filters")) {
      $("#search_filters").submit();
    };

  };

});