$('.navigation-tabs.tabs').on("click", ".menu-link", function(e) {
	e.preventDefault();

	var id = $(this).data("tab");

	var $tab = $(this).parent();
	var $allTabs = $tab.parent().find('.menu-item');

	// Set Active Tab Link
	$allTabs.removeClass("menu-item-active");
	$tab.addClass('menu-item-active');

	var $panels = $tab.parents(".tabs").next(".panels");

	// Set Active Tab Panel
	$panels.find(".tab-panel").css("visibility", "hidden");
    $panels.find("."+id).css("visibility", "visible");

    $panels.find(".tab-panel").css("position", "absolute");
    $panels.find("."+id).css("position", "relative");
    // if ($panels.find("."+id).find(".tab-content").height() <= 212 && $panels.find("."+id).find(".tab-expand").length) {
    //     $panels.find("."+id).find(".tab-expand").remove();
    // }
});

function initTabsReadMore(){
    var $expandBtn = $('.tab-expand'),
        $expandText = $('.tab-text'),
        $expandContent = $('.tab-content');

    $.each($expandBtn, function(key, value){
        var $text = $(value).prev($expandText),
            height = $text.find($expandContent).outerHeight(true);

       if (height <= 212) {
            $(value).remove();
       }else{
            $(value).css('display', 'inline-block');
       }
    });

    $expandBtn.click(function(){
        var $text = $(this).prev($expandText);
        var height = $text.find($expandContent).outerHeight(true);

        if (!$text.data('height')) {
            var originalHeight = $text.outerHeight(true);

            $text.data('height', originalHeight);
        }

        
        if ($text.hasClass('open')) {
            $text.css('maxHeight', 'none').css('height', height).
                animate({height: $text.data('height')}, 200).removeClass('open');

            $(this).text($(this).data('more'));

            return;
        }

        $text.css('maxHeight', 'none').css('height', $text.data('height')).
            animate({height: height}, 200).addClass('open');

        $(this).text($(this).data('less'));
    });
}

initTabsReadMore();