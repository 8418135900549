var GaStatistics = function(params) {
    var _this = this,
        _isMobileLabel = typeof isMobile !== 'undefined' && isMobile;

    function _trackStatsEvents(el, event) {
        var $el = $(el),
            isMobileLabel = typeof isMobile !== 'undefined' && isMobile,
            ec = "", // event category
            ea = "", // event action
            el = ""; // event label

        var isHomePage = page_name == "home",
            isCityPage = page_name == "city_detail",
            isBrandPage = page_name == "brand_detail" || page_name == "brands",
            isBrandCityPage = page_name == "brand_city";

        if (isHomePage) {
            ec = gaEventsLegend.siteStats.categories.home;
        } else if (isCityPage) {
            ec = gaEventsLegend.siteStats.categories.city;
        } else if (isBrandPage) {
            ec = gaEventsLegend.siteStats.categories.brand;
        } else if (isBrandCityPage) {
            ec = gaEventsLegend.siteStats.categories.brandCity;
        }else{
            return;
        }

        /* Secondary Header Categories */
        if ($el.hasClass('secondary_header-item-link')) {
            ea = "Category header menu";
            el = $el.text().trim();
        }
        
        if ($el.hasClass('category-dropdown-brand-link')) {
            ea = "Brands drop down menu";
            el = $el.text().trim();
        }

        if ($el.hasClass('category-dropdown-make-link')) {
            ea = "Mark drop down menu";
            el = $el.text().trim();
        }

        if ($el.hasClass('category-dropdown-product-link')) {
            ea = "Product drop down menu'";
            el = $el.text().trim();
        }
        /* END Secondary Header Categories */
        
        /* Home page events */
        if (isHomePage && $el.hasClass('ga-btn-category-name')) {
            // ec = gaEventsLegend.siteStats.categories.home;
            ea = "category";
            el = $el.text().trim();
        }

        if (isHomePage && $el.closest('.hero-cities').length && $el.hasClass('city-link')) {
            // ec = gaEventsLegend.siteStats.categories.home;
            ea = "city";
            el = $el.find('span').text().trim();
        }

        if (isHomePage && $el.hasClass('ga-button-brand-logo')) {
            ea = "brand-logo";
            el = $el.find('.store-title').text().trim();
        }

        if (isHomePage && $el.hasClass('ga-button-all-brands')) {
            // ec = gaEventsLegend.siteStats.categories.home;
            ea = "all-brands-button";
        }

        if ((isCityPage || isHomePage) && $el.closest('.promo-products-container').length && ($el.hasClass('product-link') || $el.hasClass('menu-link'))) {
            ea = "product-text-name";
            el = $el.text().trim();
        }

        if ((isHomePage || isCityPage || isBrandCityPage) && $el.hasClass('ga-product-image')) {
            ea = "product-image";

            if(isMobileLabel){
                el = $el.find('div').text().trim();
            }else{
                el = $el.parent().find('.product-link span').text().trim();
            }
        }

        if ((isHomePage || isCityPage || isBrandCityPage) && $el.hasClass('ga-button-all-products')) {
            ea = "all-products-button";
        }

        if ($el.hasClass('ga-button-all-products-footer')) {
            ea = "all-products-footer";
        }

        if ($el.hasClass('ga-button-all-brands-footer')) {
            ea = "all-brands-footer";
        }

        if (isBrandPage && $el.closest('.ga-brand-tabs-wrapper').length && $el.hasClass('menu-link')) {
            ea = "more-info-tabs";
            el = $el.text();
        }

        /* End Home page events */

        /* City page events */

        /* Search bar on magnifying glass. Remove after new header is deployed everywhere. */
        if ($el.closest('.main_search').length && $el.hasClass('typeahead') && (typeof siteSettings.site_header_redesign === 'undefined' || siteSettings.site_header_redesign == "0")) {
            var posX = event.offsetX ? (event.offsetX) : event.pageX - $el[0].offsetLeft;
            var posY = event.offsetY ? (event.offsetY) : event.pageY - $el[0].offsetTop;

            if (posX >= 0 && posY >= 0 && posX <= 51 && posY <= 48) {
                ea = "magnifying-glass";
            }
        }

        if ($el.closest('.main_search').length && $el.parent('.tt-suggestion').length && $el[0].tagName == "A") {
            ea = "search";
            el = $el.attr('lang');
        }

        if ($el.hasClass('search-submit')) {
            ea = "search";

            if ($("#search_url").val() != "") {
                el = $("#search_url").val();
            } else {
                el = window.location.origin + "/search?search=" + $('.main_search .typeahead').val();
            }
        }

        /* End Search bar */

        if ($el.closest('.header-categories').length && $el.hasClass('dropbtn')) {
            ea = "category";
            el = $el.text().trim();
        }

        if ($el.closest('.dropdown-content').length && $el.hasClass('header-category-brand')) {
            ea = "category-brand";
            el = $el.text().trim();
        }

        if (isCityPage && $el.closest('.catalog').length && $el.hasClass('ga-btn-b-title')) {
            ea = "brand-name-text";
            el = $el.text().trim();
        }

        if ((isCityPage || isBrandCityPage) && $el.closest('.ga-brochure-catalog').length && $el.hasClass('ga-btn-b-title') == false) {
            ea = "brochure-image";
            el = $el.closest('.catalog').find('a').attr('href');
        }

        if (isCityPage && $el.closest('.pagination').length) {
            ea = "pages";
            el = $el.data('page');
        }

        if (isCityPage && $el.hasClass('ga-button-all-brands')) {
            ea = "all-brands-button";
        }

        if (isCityPage && $el.hasClass('ga-btn-category-name')) {
            ea = "category";
            el = $el.text().trim();
        }

        if (isCityPage && $el.hasClass('ga-button-brand-logo')) {
            ea = "brand-logo";
            el = $el.find('.store-title').text().trim();
        }

        if (isCityPage && $el.hasClass('city_large-banner-link')) {
            ea = "offer-banner";
            el = $el.attr("href");
        }

        if (isCityPage && $el.hasClass('city_small-banner-link')) {
            ea = "banner 300-250";
            el = $el.attr("href");
        }

        if (isCityPage && $el.hasClass('city_medium-banner-link')) {
            ea = "promotion with text";
            el = $el.attr("href");
        }

        if ($el.hasClass('city_slider-logo-link')) {
            ea = $el.hasClass("city_slider-logo-make") ? "mark logo slider" : "brand logo slider";
            el = $el.attr("title");
        }

        if (isCityPage && $el.hasClass('city_banner_brochures-link')) {
            ea = "campaign-banner";
            el = $el.attr("href");
        }

        if ($el.closest(".city_slider").length && ($el.hasClass('swiper-button-next') || $el.hasClass('swiper-button-prev'))) {
            ea = $el.closest(".city_slider").find(".city_slider-logo-make").length > 0 ? "mark-slider-arrow" : "brand-slider-arrow";
            el = $el.hasClass('swiper-button-next') ? "right" : "left";
        }

        if ($el.hasClass('city_product-logo-link')) {
            
            if($el.closest(".brand_brochures-product-images").length) { //products in brand brochures section
                ea = $el.closest(".brand_brochures-product-images").data("brand") + " - mark logo over product";
            } else {
                ea = "mark logo over product";
            }
            
            el = $el.attr("title");
        }
        
        if ($el.hasClass('city_product-photo-link')) {

            if($el.closest(".brand_brochures-product-images").length) { //products in brand brochures section
                ea = $el.closest(".brand_brochures-product-images").data("brand") + " - product image";
            } else {
                ea = $(".brand_brochures-product-images").data("brand") + " - product image";
            }

            el = $el.closest(".city_product").find(".city_product-bottom-title").text().trim();
        }

        if (isBrandCityPage && $el.hasClass('brand_city_brochure-product')) {
            ea = $el.closest(".brand_brochure-wrapper").find(".vr-catalog__title_style").text().trim() + " - product text";
            el = $el.text().trim();
        }

        if ($el.hasClass('city_product-bottom-price-link')) {
            ea = "product price";
            el = $el.attr("href");
        }

        if (isCityPage && $el.hasClass('city_promotion-link')) {
            ea = "promotion slider";
            el = $el.attr("href");
        }

        if (isCityPage && $el.hasClass('city_category-link')) {
            ea = "product main categories footer";
            el = $el.attr("data-name");
        }

        if (isCityPage && $el.hasClass('city_category-product-link')) {
            ea = "product subcategories footer";
            el = $el.attr("data-name");
        }

        if (isCityPage && $el.hasClass('city_products-more-btn')) {
            ea = "product button see more";
            el = "";
        }

        if (isCityPage && $el.closest(".city_promotions").length && ($el.hasClass('swiper-button-next') || $el.hasClass('swiper-button-prev'))) {
            ea = "promotion slider arrow";
            el = $el.hasClass('swiper-button-next') ? "right" : "left";
        }

        if (isCityPage && $el.closest(".city_top-section").length && ($el.hasClass('swiper-button-next') || $el.hasClass('swiper-button-prev'))) {
            ea = "promotion text slider arrow";
            el = $el.hasClass('swiper-button-next') ? "right" : "left";
        }

        if (isCityPage && $el.hasClass('city_other-city')) {
            ea = "other-cities footer section";
            el = $el.text().trim();
        }

        if (isCityPage && $el.hasClass('city_brand-link')) {
            var city = window.location.pathname;
            var citySlice = city.slice(1);
            ea = "shops in this city";
            el = $el.text().trim() + " - " + citySlice;
        }

        // if (isCityPage && $el.hasClass('swiper-button-next') && $el.hasClass('animated')) {
        //     ea = "Category-right-arrow";
        // }
        /* End City page events */

        if ($el.closest('#header-select').length && ($el.hasClass('tt-suggestion') || $el.hasClass('tt-link'))) {
            ea = "city-pin";
            el = $el.text().trim();
        }

        if (isBrandPage && $el.closest('.ga-top-brands-wrapper').length && $el.closest('.store').length) {
            ea = "top-brands-logo";
            el = $el.closest('.store').find('.store-title').text().trim();
        }

        if(isBrandCityPage && $el.hasClass('ga-btn-see-brochure')){
            ea = "see-brochure-button";
            el = isMobileLabel ? $el.closest('.ga-brochure-info').attr('href') : $el.attr('href');
        }

        if(isBrandCityPage && $el.closest('.brand-tabs').length){
            ea = $el.data('tab') + "-tab";

            if($el.data('tab') == 'offers'){
                el = $('input[name=brandName]').val().trim();
            }else if($el.data('tab') == 'products'){
                el = $el.attr('href');
            }
        }

        if(isBrandCityPage && $el.closest('.ga-brochure-info').length && $el.hasClass('blue-cursor')){
            ea = "product-text";
            el = $el.text().trim();
        }

        if(isBrandCityPage && $el.hasClass('ga-btn-all-similar')){
            ea = "all-similar-brochures-button";
        }

        if(isBrandCityPage && $el.hasClass('brand_top_products')){
            ea = Brands + '-product-text-section';
            el = $el.text().trim();
        }
        
        if(isBrandCityPage && $el.closest('.ga-all-similar-wrapper').length && $el.hasClass('ga-btn-all-similar') == false){
            ea = "similar-brochure";
            el = $el.closest('.catalog').find('a').attr('href');
        }
        
        /* Mobile */
        if($el.closest('.categories-list').length){
            ea = "category";
            el = $el.text().trim();
        }

        if(isHomePage && $el.hasClass('open-categories')){
            ea = "category-choose-button";
        }

        if((isHomePage || isCityPage || isBrandPage || isBrandCityPage) && $el.hasClass('open-select-city-global')){
            ea = "city-pin";
            el = $el.text().trim();
        }

        if((isHomePage || isCityPage || isBrandPage || isBrandCityPage) && $el.closest('.select-city-global .letters-list').length){
            ea = "city-pin";
            el = $el.text().trim();
        }

        if((isHomePage || isCityPage || isBrandPage || isBrandCityPage) && $el.hasClass('open-search')){
            ea = "magnifying-glass";
            el = $el.text().trim();
        }

        if((isHomePage || isCityPage || isBrandPage || isBrandCityPage) && $el.hasClass('do-search')){
            ea = "search";
            el = window.location.origin + "/search?search=" + $el.closest('.navbar-search').find('.input-search').val().trim();
        }

        if((isHomePage || isCityPage || isBrandPage || isBrandCityPage) && $el.closest('.content-search').length && $el.closest('.tt-suggestion a').length){
            ea = "search";
            el = $el.closest('.tt-suggestion a').attr('lang');
        }

        if(isCityPage && ($el.closest('.open-sidemenu').length || $el.hasClass('open-sidemenu'))){
            ea = "menu";
        }

        if(isCityPage && $el.hasClass('ga-btn-brochures-see-more')){
            ea = "see-more-brochures-button";
        }

        if(isCityPage && $el.hasClass('ga-btn-brands-see-all')){
            ea = "all-brands-button";
        }

        if(isBrandPage && $el.hasClass('ga-button-brand-logo')){
            ea = "top-brands-logo";
            el = $el.find('.store-title').text().trim();
        }

        if(isBrandPage && $el.closest('.menu-info-header').length && $el.hasClass('tab-info')){
            ea = "more-info-tabs";
            el = $el.text().trim();
        }
        

        if (ec !== "" && ea !== "") {
            ec += isMobileLabel ? "-mobile" : "-desktop";

            if (el !== "") {
                ga('send', 'event', ec, ea, el);
            } else {
                ga('send', 'event', ec, ea);
            }
        }
    }

    function _init() {
        var isMobileLabel = typeof isMobile !== 'undefined' && isMobile;

        if(isMobileLabel){
            var gaClickSelectorMobile = '.categories-list a, ' +
            '.open-categories, ' +
            '.select-city-global .letters-list a, ' +
            '.content-search .tt-suggestion, ' +
            '.open-sidemenu, ' +
            '.ga-btn-brands-see-all, ' +
            '.menu-info-header .tab-info'
            ;

            $(document).on('click', gaClickSelectorMobile, function(e) {
                var $el = $(e.target);

                _trackStatsEvents(e.target, e);
            });
        }else{
            var gaClickSelectorDesktop = '#header-select .tt-suggestion, ' +
            '.main_search .search-submit, ' + 
            '.main_search .tt-suggestion a, ' +
            '.ga-brand-tabs-wrapper .menu-link, ' +
            '.ga-top-brands-wrapper .store,' + 
            '.brand-tabs a[data-tab="products"], ' + 
            '.brand-tabs a[data-tab="offers"], ' + 
            '.ga-brochure-info .blue-cursor, ' +
            '.ga-btn-all-similar'
            ;
            
            $(document).on('click', gaClickSelectorDesktop, function(e) {
                var $el = $(e.target);

                _trackStatsEvents(e.target, e);
            });
        }

        
    }

    function _track(element, event) {
        /* data-ga-action="" data-ga-label="url" */
        var $elem = $(element),
            eaType = $elem.data('ga-action') || '',
            elType = $elem.data('ga-label') || '',
            ec = $elem.data('ga-category') || '',
            ea = '',
            el = '',
            gaDataObj = {};

        var isHomePage = page_name == "home",
            isCityPage = page_name == "city_detail",
            isBrandPage = page_name == "brand_detail" || page_name == "brands",
            isBrandCityPage = page_name == "brand_city";
        
        if(ec === ""){
            if (isHomePage) {
                ec = gaEventsLegend.siteStats.categories.home;
            } else if (isCityPage) {
                ec = gaEventsLegend.siteStats.categories.city;
            } else if (isBrandPage) {
                ec = gaEventsLegend.siteStats.categories.brand;
            } else if (isBrandCityPage) {
                ec = gaEventsLegend.siteStats.categories.brandCity;
            }else{
                return;
            }
        }

        ec += _isMobileLabel ? "-mobile" : "-desktop";

        switch (elType) {
            case "url":
                el = $elem.attr('href');
                break;
            case "text":
                el = $elem.text().trim();
                break;
            default:
                el = elType;
        }

        switch (eaType) {
            case "url":
                ea = $elem.attr('href');
                break;
            case "text":
                ea = $elem.text().trim();
                break;
            default:
                ea = eaType;
        }

        if ($elem[0].tagName == "A") {
            if(typeof event !== 'undefined' && $(event.target) != $elem){
                return;
            }

            if (typeof event !== 'undefined') {
                if($elem[0].target.length == 0){
                    event.stopPropagation();
                    event.preventDefault();
                }
            }
            
            if($elem[0].target.length == 0){
                gaDataObj.hitCallback = function() {
                    window.location.href = $elem[0].href;
                };
            }
        }

        if (ec !== "" && ea !== "") {

            gaDataObj.eventCategory = ec;
            gaDataObj.eventAction = ea;

            if (el !== "") {
                gaDataObj.eventLabel = el;
            }

            ga('send', 'event', gaDataObj);
        } else if (typeof gaDataObj.hitCallback === 'function') {
            gaDataObj.hitCallback();
        }
    }

    _this.trackStatsEvents = _trackStatsEvents;
    _this.init = _init;
    _this.track = _track;
};