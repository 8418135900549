$('.close-cookies-bar').click(function()
{
    $.post("//"+domain+"/ajx/ajax.file.php", {fn: 'cookiesAccept'}, function(data){
        hideCookiesBar();
    });
});

function hideCookiesBar()
{
	$body = $('body');
	$bar = $('.cookies-bar');
	$cover = $('.cover');

	/*$body.animate({
		paddingTop: 0
	}, 200);*/

	/*$cover.animate({
		top: 0
	}, 200);*/

	$bar.animate({
		bottom: -50
	}, 200);
}