var UpNavigation = function(params){
    var _this = this,
        _defaultToggleDelay = 300;

    function _handleClicks(){
        $(document).on('click', '.sh-wrap .up-nav .up-has-sub-item .up-sub-item-arrow-wrap', function(e){
            e.preventDefault();
            e.stopPropagation();

            var $parent = $(this).parent(),
                $subItemWrap = $parent.next('.up-nav-sub-item-wrap');

            if($parent.hasClass('open')){
                if($subItemWrap.is(':visible')){
                    $parent.removeClass('up-nav-arrow-open');
                    $subItemWrap.stop().slideToggle(_defaultToggleDelay, function(){
                        $parent.removeClass('open');
                    });
                }
            }else{
                if($subItemWrap.is(':hidden')){
                    $parent.addClass('up-nav-arrow-open');
                    $subItemWrap.stop().slideToggle(_defaultToggleDelay, function(){
                        $parent.addClass('open');
                    });
                }
            }
        });
    }

    function _init(){
        _handleClicks();
    }

    _init();

    _this.init = _init;
};