var topBannerEvents = function() {
	var _this = this;

	function _init(domain, action, label) {
		var _device = isMobile ? "mobile" : (isTablet ? "tablet" : "desktop"),
			_page = page_name !== "city_detail" ? "brand-" : "",
			_eventCategory = 'banner-top-' + _page + 'city-' + _device,
			_eventAction = action === "click" ? action : action.replace(/#.*$/g, '').replace(domain, ''),
			_eventLabel = label || false;

		if(!_eventLabel) {
			ga("send", "event", {eventCategory: _eventCategory, eventAction: _eventAction});
		}
		else {
			ga("send", "event", {eventCategory: _eventCategory, eventAction: _eventAction, eventLabel: _eventLabel});
		}
	}

	_this.emitEvent = _init
};

var detectTopBannerEvents = new topBannerEvents();
