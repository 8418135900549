function goToBrochurePage(url) {
    window.open(url, '_blank');
}

function goToBrand(id, element, url) {

    link = $(element).attr('href');
    if (typeof(link) == 'undefined' || link == '#') {
        link = url;
    }

    page = $(element).data('page');
    product_id = 0;
    if ($(element).hasClass('brochure-product-link')) {
        product_id = $(element).data('id');
    }

    $.ajax({
        url: "//" + domain + "/ajx/ajax.file.php",
        dataType: 'jsonp',
        jsonp: "callback",
        jsonpCallback: "goToBrandCallback",
        data: { fn: "goToBrand", id: id, link: link, page: page, product_id: product_id }
    });
}

function goToBrandCallback(data) {
    if (data.link == window.location.href) {
        window.location.reload(true);
    } else {
        window.location = data.link;
    }
    return false;
}

$(document).ready(function() {
    $(document.body).on('click', "button.back-to-top", function() {
        $('html, body').animate({ scrollTop: '0px' }, "slow");
    });
    $(window).scroll(function(event) {
        var scroll = $(window).scrollTop(),
            backToTop = $("button.back-to-top");
        if (scroll > 250) {
            backToTop.fadeIn();
        } else {
            backToTop.fadeOut();
        }
    });


    $(document).on("click", ".see_more__holiday", function() {
        var li = $("li.hidden"),
            count = li.length,
            not_hidden_count, top, i, li_active_count,
            brochures_count = smartyVARS.brochures_count;
        if (brochures_count) {
            if (count > 10) { count = 9; }
            if (count) {
                top = $(".see_more__holiday").offset().top;
                for (i = 0; i <= count; i++) {
                    li.eq(i).removeClass("hidden");
                }
            }
            $('html, body').animate({ scrollTop: (top - 50) }, "slow");
            li_active_count = $("li.li-brochu").not(".hidden").length;
            if (count < 9 || li_active_count == brochures_count) { $(this).hide(); }
            not_hidden_count = $("li.li-brochu").not(".hidden").length;
            $(".pagination-info").find("span").text(not_hidden_count);
        }
    });
    $(document).on("click", ".campaign", function() {
        var page = smartyVARS.brochures_count,
            campaign = $(this).data("name");
        if (campaign) {
            $.post('/ajx/ajax.file.php', { fn: 'setAnalyticsPage', page: page, campaign: campaign }, function(data) {
                // hi :)
            }, 'json');
        }
    });


    $(document).on("click", "input[name='holiday_subscribe']", function() {
        var city_id = smartyVARS.city_id,
            city_name = smartyVARS.city_name,
            wrong_email_text = smartyVARS.wrong_email_text,
            no_email = smartyVARS.no_email,
            email_filed = $("input[name='email']"),
            email = email_filed.val(),
            wrong_email = $(".wrong_email"),
            error = false;

        if (email.length == 0) {
            email_filed.css({ border: '1px solid #eb4141', background: '#F6F6F6' }).focus();
            wrong_email.text(no_email).css({ color: "#eb4141", fontSize: "8pt", display: "block" });
        } else {
            if (validateEmail(email)) {
                $.post("/ajx/ajax.file.php", {
                    fn: "brochureSubscribe",
                    "brands[]": "all",
                    "type": "general",
                    "from_page": "holidays:index",
                    "city": city_id,
                    "selectCityName": city_name,
                    "subscriber": email
                }, function(data) {
                    if (!data.status) {
                        error = true;
                        wrong_email.text(data.msg).css({ color: "#eb4141", fontSize: "8pt", display: "block" });
                    } else {
                        email_filed.val('');
                        email_filed.css({ border: '1px solid #dedbdb', background: '#fff' });
                        wrong_email.text(data.msg).css({ color: "#2ab64e", fontSize: "8pt", display: "block" });
                    }
                }, "json");
            } else {
                error = true;
                wrong_email.text(wrong_email_text).css({ color: "#eb4141", fontSize: "8pt", display: "block" });
            }
        }
        return false;
    });

    function validateEmail(email) {
        var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(email);
    }

    $(".season_products a").on("click", function(e) {
        $.post("/ajx/ajax.file.php", {
            fn: "setSeasonCount",
            "title": $(this).attr("title")
        }, function(data) {}, "json");
    });

    $(".breadcrumb_home").on("click", function() {
        var url = $(this).attr("href");
        $.post('/ajx/ajax.file.php', { fn: 'breadcrumbHomepage' }, function(data) {
            location.href = url;
        }, 'json');
        return false;
    });

    if (smartyVARS.is_not_home_page) {
        var today = new Date();
        if (today.getDay() != 6 && today.getDay() != 0) {
            if ($(".notification").length) {
                setInterval(checkForNewBrochures, 60000);
            }
        }

        function checkForNewBrochures() {
            var notification = $(".notification"),
                notifications = $("#notifications"),
                count = notification.not(".hide").length,
                parent,
                ids = new Array(),
                city_id = smartyVARS.city_id;
            if (count) {
                notification.each(function() {
                    ids.push($(this).data("id"));
                });
            }
            if (!count) {
                $.post("//" + domain + "/main/getnewbrochures", {
                    "ids": ids,
                    "city_id": city_id
                }, function(data) {
                    if (!$(".ad_notification").length > 0) { // this checks if there is another notifictaion   

                        if (data.status) {
                            $.each(data.brochures, function(index, value) {
                                if (index == 0) {
                                    notification.find(".notification_link").attr("href", value.url);
                                    notification.fadeIn(function() {
                                        $(this).removeClass("hide");
                                    });
                                    notification.attr("data-id", value.id);
                                    notification.find("i").text(value.brand_name);
                                } else {
                                    clone = notification.clone(),
                                        parent = clone.find(".brochure_link").parents(".notification").css("opacity", "1");
                                    parent.attr("data-id", value.id).attr("data-num", index);
                                    clone.find("i").text(value.brand_name);
                                    clone.find(".notification_link").attr("href", value.url);
                                    clone.fadeIn(function() {
                                        $(this).removeClass("hide");
                                    });
                                    notifications.prepend(clone);
                                }
                            });
                            setTimeout(hideNotifications, 20000);
                        }
                    } else {
                        // console.log(2);
                    }
                }, "json");
            }
        }

        function hideNotifications() {
            var notification = $(".notification");
            $(".notification:not(:first)").fadeOut(function() {
                $(this).remove();
            });
            notification.eq(0).fadeOut();
            notification.addClass("hide");
        }
        $(document).on('click', ".notification .close_icon", function() {
            $(this).parents(".notification").remove();
        });

    }


    if (smartyVARS.brand_page_not_in_city) {
        var option_active = $(".panels").find(".show_more_option.active"),
            option_len = option_active.find("p").length;
        if (option_len > 2) {
            option_active.find("p:gt(1)").addClass("hide");
            option_active.find("a.action").removeClass("hide");
        }

        $(document).on("click", "a.menu-link", function() {
            var current_tab = $(this).data("tab");

            $(".show_more_option.active").removeClass("active");
            $("." + current_tab).addClass("active");            
        });

        $(document).on("click", ".panels a.action", function() {
            var after_third = $(".panels").find(".show_more_option.active").find("p:gt(1)");
            if ($(this).hasClass("close")) {
                after_third.removeClass("hide");
                $(this).removeClass("close").addClass("open");
                $(this).text(showLessInfo);
            } else {
                after_third.addClass("hide");
                $(this).removeClass("open").addClass("close");
                $(this).text(showMoreInfo);
            }
        });
    }
    if (smartyVARS.holiday_countdown) {

        var time = smartyVARS.holidayCountdown * 1000;
        if (new Date().getTime() < time) {
            var clock;

            clock = $('.count-wrap').FlipClock((time - new Date().getTime()) / 1000, {
                clockFace: 'DailyCounter',
                autoStart: true,
                callbacks: {
                    stop: function() {
                        console.log('The clock has stopped!');
                        $(".count-back").remove();
                    }
                },
                language: 'bg',
                countdown: true
            });
        } else {
            $(".count-back").remove();
        }
    }
});