$(".modal-close-btn").on("click", function(){
	var $target = $(this).parents(".modal");

	$target.fadeOut(200);

	ifModalMustBeAnalyzedOn( $target, 'close::cross'); // Close via close button (x)
});

$(".modal .button-cancel").on("click", function(){
	var $target = $(this).parents(".modal");

	$target.fadeOut(200);

	ifModalMustBeAnalyzedOn( $target, 'close::cancel'); // Close via cancel button (no thnaks)
});

$(".modal .button-existing-subscriber").on("click", function(){
	var $target = $(this).parents(".modal");

	$target.fadeOut(200);

	ifModalMustBeAnalyzedOn( $target, 'close::existing'); // Close via I'm old Subscriber button
});

$(".modal .button-completed").on("click", function(){
	var $target = $(this).parents(".modal");

	$target.fadeOut(200);
});

$(".modal").on("click", function(e){
	if ($(e.target).is(".modal")) {
		$(this).fadeOut(200);

		ifModalMustBeAnalyzedOn( $(this), 'close::overlay'); // Close via overlay (the dark transparent thing)
	};
});

/* Feedback */
setTimeout(function(){
$("#feedback_link").animate({opacity:0},200,function(){
	$("#feedback_link").animate({opacity:1},200,function(){
		$("#feedback_link").animate({opacity:0},200,function(){
			$("#feedback_link").animate({opacity:1},200,function(){});
		});
	});
});
}, 2000);

/* FB GP*/
function PopupCenter(url, title, w, h) {
    // Fixes dual-screen position                         Most browsers      Firefox
    var dualScreenLeft = window.screenLeft != undefined ? window.screenLeft : screen.left;
    var dualScreenTop = window.screenTop != undefined ? window.screenTop : screen.top;

    width = window.innerWidth ? window.innerWidth : document.documentElement.clientWidth ? document.documentElement.clientWidth : screen.width;
    height = window.innerHeight ? window.innerHeight : document.documentElement.clientHeight ? document.documentElement.clientHeight : screen.height;

    var left = ((width / 2) - (w / 2)) + dualScreenLeft;
    var top = ((height / 2) - (h / 2)) + dualScreenTop;
    var newWindow = window.open(url, title, 'scrollbars=yes, width=' + w + ', height=' + h + ', top=' + top + ', left=' + left);

    // Puts focus on the newWindow
    if (window.focus) {
        newWindow.focus();
    }
};

$("#fb_left").on('click', function(e){
	e.preventDefault();

    var sharer = "https://www.facebook.com/sharer/sharer.php?u=";
	var url = sharer + window.location.href;

	PopupCenter(url, "", 563, 500);

	ga('send', 'event', 'share', 'facebook-left');
});

$("#gp_left").on('click', function(e){
	e.preventDefault();

    var sharer = "https://plus.google.com/share?url=";
	var url = sharer + window.location.href;

	PopupCenter(url, "", 563, 500);

	ga('send', 'event', 'share', 'google-plus-left');
});

$(".fb_share_btn").on('click', function(e){
	e.preventDefault();

    var sharer = "https://www.facebook.com/sharer/sharer.php?u=";
	var url = sharer + $(this).data("url");

	PopupCenter(url, "", 563, 500);

	ga('send', 'event', 'share', 'facebook-broshure');
});

$(".gp_share_btn").on('click', function(e){
	e.preventDefault();

    var sharer = "https://plus.google.com/share?url=";
	var url = sharer + $(this).data("url");

	PopupCenter(url, "", 563, 500);

	ga('send', 'event', 'share', 'google-plus-broshure');
});

/* Detect page Leave */
$('html').on('mouseleave', function(e){
	if(window.location.hash == ""){
		showSubscribeDialog();
	}
});

function showSubscribeDialog()
{
	var modalSelector = ".subscribe-general";
	var key = "subscribe.modal.v"+$(modalSelector).data("version");
	
	if (!Cookies.get(key)) {
		$(modalSelector).fadeIn(100);
	};

}

function ifModalMustBeAnalyzedOn(targetModal, eventName)
{
	var version = targetModal.data("version");

	if (!version) { return; };

	var key = "subscribe.modal.v"+version;
	var year = 365, week = 7 ;

	/*if (Cookies.set(key)) {
		return;
	};*/

	if (eventName === "close::cancel") {
		//Cookies.set(key, 1, { expires: week }); // For the session
		Cookies.set(key, 1, { expires: year });
	};

	if (eventName === "close::cross") {
		//Cookies.set(key, 1); // For the session
		Cookies.set(key, 1, { expires: year });
	};

	if (eventName === "close::overlay") {
		//Cookies.set(key, 1); // For the session
		Cookies.set(key, 1, { expires: year });
	};

	if (eventName === "close::existing") {
		Cookies.set(key, 1, { expires: year });
	};

	if (eventName === "success::subscribed") {
		Cookies.set(key, 1, { expires: year });
	};
}


/* Facebook Like Popup */

if ( $('.popup-facebook').length ) {

	var key = "facebook.like.closed";
	
	$(".popup-facebook .fb-close-btn").on('click', function(){

		$('.popup-facebook').fadeOut(300);

		Cookies.set(key, 1, { expires: 365 });
	});

	if (!Cookies.set(key)) {
		$(".popup-facebook").delay(1000).fadeIn(300);
	}
}

