if (typeof offerista_tracking !== "undefined" && offerista_tracking) {
    var BrochureImpression = function(brochureClass){
        var _this = this,
            //pages = isMobile ? $(".catalog") : $(".catalog .catalog-image"),
            //sentBrochureImpressionsArr = [],
            pendingBrochureImpressionsArr = [];
        
        window.sentBrochureImpressionsArr = [];

        // missing forEach on NodeList for IE11
        if (window.NodeList && !NodeList.prototype.forEach) {
            NodeList.prototype.forEach = Array.prototype.forEach;
        }

        function debounce(func, wait, immediate) {
            var timeout;
            return function() {
                var context = this, args = arguments;
                var later = function() {
                    timeout = null;
                    if (!immediate) func.apply(context, args);
                };
                var callNow = immediate && !timeout;
                clearTimeout(timeout);
                timeout = setTimeout(later, wait);
                if (callNow) func.apply(context, args);
            };
        };

        function genereateImpressionTrackingData() {
            var brochureImpressionTracking = new OfferistaTracking();
            var brochuresObjectsArray = [];    

            for (i=0; i<pendingBrochureImpressionsArr.length; i++) {
                brochuresObjectsArray[i] = (new OfferistaTracking()).data.trackings[0];
                brochuresObjectsArray[i].type = "BROCHURE_IMPRESSION"
                brochuresObjectsArray[i].objectId = pendingBrochureImpressionsArr[i];
            }
            
            brochureImpressionTracking.sendTracking({fn: "offeristaTracking", trackings: brochuresObjectsArray});
            pendingBrochureImpressionsArr = [];
        }

        function checkPage() {
            var pages = $(brochureClass);

            $.each(pages, function(i, page) {
                // half way through the image
                var scrollInPage = (window.pageYOffset + window.innerHeight);
                // bottom of the image
                var imageBottom = $(page).offset().top + $(page).height();
                var isHalfShown = scrollInPage > $(page).offset().top;
                var isNotScrolledPast = window.pageYOffset < (imageBottom - 50);

                var brochureOfferistaId = Number($(page).attr('data-offerista-id'));
                
                if (isHalfShown && isNotScrolledPast) {
                    if(sentBrochureImpressionsArr.indexOf(brochureOfferistaId) === -1 && brochureOfferistaId > 0) {
                        sentBrochureImpressionsArr.push(brochureOfferistaId);
                        pendingBrochureImpressionsArr.push(brochureOfferistaId);
                    }
                }
            });
            
            if (pendingBrochureImpressionsArr.length) {
                genereateImpressionTrackingData();
            }

        }

        function init() {
            checkPage();
            window.addEventListener('scroll', debounce(checkPage, 100, true));
        }

        _this.init = init;
    }

    switch (page_name) {
        case "city_detail":
            var brochureImpression = new BrochureImpression(isMobile ? ".catalog" : ".catalog .catalog-image");
            break;
        case "brand_city":
            var brochureImpression = new BrochureImpression(isMobile ? ".vr-catalog__image, .ga-brochure-catalog" : ".vr-catalog__image, .catalog-image");
            break;
        case "brochure_view":
            var brochureImpression = new BrochureImpression(isMobile ? ".vr-catalog__image" : ".vr-catalog__image");
            break;
        case "campaign_index":
            var brochureImpression = new BrochureImpression(".vr-catalog__image, .frame__aside .info-box");
            break;
        case "brand_detail":
            var brochureImpression = new BrochureImpression(".catalog-image:last");
            break;
        case "brands":
            var brochureImpression = new BrochureImpression(".catalog-image:last");
            break;     
        case "category_detail":
            var brochureImpression = new BrochureImpression(".catalog-image");
            break;    
        case "categories":
            var brochureImpression = new BrochureImpression(".catalog");
            break;       
        default: 
            break;
    }

    brochureImpression.init();
}