var gaEventsLegend = {
    appPromo: {
        "home":{
            icon: {
                apple: "home-icon-apple",
                android: "home-icon-android",
            },
            footer: {
                apple: "home-footer-static-apple",
                android: "home-footer-static-android",
                landing: "home-footer-static-landing",
            }
        },
        "city_detail": {
            header_icon: "city-header-icon",
            footer: {
                apple: "city-footer-static-apple",
                android: "city-footer-static-android",
                landing: "city-footer-static-landing"
            },
            static_img: {
                apple: "city-static-apple",
                android: "city-static-android",
                landing: "city-static-landing"
            },
            animated_img: {
                apple: "city-animated-apple",
                android: "city-animated-android",
                landing: "city-animated-landing"
            }
        },
        "brand_detail": {
            header_icon: "brand-header-icon",
            footer: {
                apple: "brand-footer-static-apple",
                android: "brand-footer-static-android",
                landing: "brand-footer-static-landing"
            },
            static_img: {
                apple: "brand-static-apple",
                android: "brand-static-android",
                landing: "brand-static-landing"
            },
        },
        "brand": {
            header_icon: "brand-header-icon",
            footer: {
                apple: "brand-footer-static-apple",
                android: "brand-footer-static-android",
                landing: "brand-footer-static-landing"
            },
            static_img: {
                apple: "brand-static-apple",
                android: "brand-static-android",
                landing: "brand-static-landing"
            },
        },
        /* mobile page */
        "brands": {
            header_icon: "brand-header-icon",
            footer: {
                apple: "brand-footer-static-apple",
                android: "brand-footer-static-android",
                landing: "brand-footer-static-landing"
            },
            static_img: {
                apple: "brand-static-apple",
                android: "brand-static-android",
                landing: "brand-static-landing"
            }
        },
        "brand_city": {
            header_icon: "brand-city-header-icon",
            footer: {
                apple: "brand-city-footer-static-apple",
                android: "brand-city-footer-static-android",
                landing: "brand-city-footer-static-landing"
            },
            animated_img: {
                apple: "brand-city-animated-apple",
                android: "brand-city-animated-android",
                landing: "brand-city-animated-landing"
            }
        },
        "holidays_detail": {
            header_icon: "thematic-header-icon",
            static_img: {
                apple: "thematic-apple",
                android: "thematic-android",
                landing: "thematic-landing"
            },
            animated_img: {
                apple: "thematic-animated-apple",
                android: "thematic-animated-android",
                landing: "thematic-animated-landing"
            },
            footer: {
                apple: "thematic-footer-static-apple",
                android: "thematic-footer-static-android",
                landing: "thematic-footer-static-landing"
            }
        },
        "no_page": {
            header_icon: "other-pages-header-icon",
            footer: {
                apple: "other-pages-footer-static-apple",
                android: "other-pages-footer-static-android",
                landing: "other-pages-footer-static-landing"
            }
        }
    },
    siteStats: {
        categories: {
            home: 'home-clicks',
            city: 'city-clicks',
            brand: 'brand-clicks',
            brandCity: 'brand-city-clicks'
        }
    }
};