var HeaderCategories = function(params) {
    var _this = this,
        _cachedCats = {},
        categoriesIdsArray = [];

    function isInArray(value, array) {
        return array.indexOf(value) > -1;
    }

    /* Load categories on mouseover */
    function _handleCategoriesLoading() {
        $('.sh-cat-nav').on('mouseenter', '.sh-cat-nav-item', function(e) {
            var $el = $(this),
                categoryId = $el.data("category-id"),
                cityId = $el.data("city-id"),
                categoryName = $(this).find(".sh-cat-nav-item-label").text(),
                categoryNameEvent = categoryName.replace(/\s+/g, "-");

                //categoriesIdsArray.push(categoryId);
                $(".sh-cat-image-wrap .sh-cat-url-img").data("ga-label", categoryNameEvent);
                $(".sh-cat-image-wrap .sh-cat-url-text").data("ga-label", categoryNameEvent);

            if ($el.hasClass("loaded") == false) {

                $.ajax({
                    type: "GET",
                    url: webpath + "/categories/get_brand_by_category/" + categoryId + "/" + cityId + "",
                    dataType: "text",
                    success: function(data) {
                        if (typeof data !== 'undefined' && data != '') {
                            data = $.parseJSON(data);
                            _cachedCats["cat_" + categoryId] = data;

                            if (typeof data.brands_alphabet !== 'undefined' && data.brands_alphabet) {
                                _populateCategoriesTopBrands(data, $el);
                                _populateCategoryImgAndTxt(data, $el);

                            }

                            $el.addClass("loaded");
                        }
                    }
                });
            }else{
                _populateCategoriesTopBrands(_cachedCats["cat_" + categoryId], $el);
                _populateCategoryImgAndTxt(_cachedCats["cat_" + categoryId], $el);
            }
        });
    }

    /*Header categories on mouseover*/
    function _populateCategoriesTopBrands(data, $el) {
        data = data.brands_alphabet;
        
        var $catTopBrandsWrap = $el.closest('.sh-cat-wrap').find('.sh-cat-top-brands-inner-wrap'),
            $catTopBrands = $catTopBrandsWrap.find('sh-cat-top-brands'),
            $catTopBrandsText = $catTopBrandsWrap.find('.sh-cat-top-brands-text'),
            topBrandCnt = 0,
            $groupWrapStr = '',
            $groupItems = '',
            $groupItemsTop = '';


        $catTopBrandsWrap.empty();

        $.each(data, function(inx, letter) {

            $.each(letter, function(lInx, brand) {

                switch(topBrandCnt){
                    case 0:
                        $groupItemsTop = '<div class="fx-row">';
                        break;
                    case topBrandCnt % 2 == 0:
                        $groupItemsTop = '</div><div class="fx-row">';
                        break;
                }

                if(brand.paid === true){
                    $groupItemsTop += '<div class="sh-cat-brand-item sh-cat-top-brand col-lg-4"><a onclick="gaStats.track(this)" data-ga-action="Category-brand" data-ga-label="' + brand.label + '" class="sh-cat-top-brand-img" href="' + brand.seo_link + '" title="' + brand.label + '" style="background-image: url(' + brand.logo + ');"></a></div>';
                    topBrandCnt++;
                }else{
                    $groupItems += '<div class="sh-cat-brand-item col-lg-4"><a onclick="gaStats.track(this)" data-ga-action="Category-brand" data-ga-label="' + brand.label + '" href="' + brand.seo_link + '">' + brand.label + '</a></div>';
                }
            });

        });

        $groupItemsTop += '</div>';

        $groupWrapStr = $groupItemsTop + $groupItems + '</div>';

        $catTopBrandsWrap.append($groupWrapStr);
    }

    function _populateCategoryImgAndTxt(data, $el) {
        var $catImgTxtWrap = $el.closest('.sh-cat-wrap').find('.sh-cat-image-wrap');

        if (typeof data.head_image_txt !== 'undefined' && data.head_image_txt) {
            $catImgTxtWrap.find('.sh-cat-url-text').text(data.head_image_txt);
        } else {
            $catImgTxtWrap.find('.sh-cat-url-text').remove();
            $catImgTxtWrap.find('.sh-cat-url-text-img').height('100%');
        }

        if (typeof data.head_image_src !== 'undefined') {
            $catImgTxtWrap.find('.sh-cat-url-img').css('background-image', 'url(' + data.head_image_src + ')');
        } else {
            $catImgTxtWrap.find('.sh-cat-url-text-img').remove();
        }

        
        if (typeof data.head_image_link !== 'undefined' && data.head_image_link) {
            $catImgTxtWrap.find('.sh-cat-url').attr('href', data.head_image_link);
        }
    }

    function _init() {
        _handleCategoriesLoading();

        $(".secondary_header .secondary_header-item").hover(function(e) {
            
            $(this).toggleClass("active-category");

            var categoryId = $(this).data("category-id");
            var $el = $(this);

            if ($el.hasClass("loaded") == false) {
                $.ajax({
                    type: "GET",
                    url: webpath + "/categories/get_menu_by_category/" + categoryId,
                    dataType: "text",
                    success: function(data) {
                        if (typeof data !== 'undefined' && data != '') {
                            data = $.parseJSON(data);
                            var brands = '', makes = '', products = '';
                            var city_seo_link = data.city ? '/' + data.city.seo_link : '';

                            $el.find('.secondary_header-banner img').attr('src', data.category.head_image_src).attr('alt', data.category.header_title);

                            if (data.brands_top.length || data.brands_other.length) {
                                for (var i=0; i < data.brands_top.length; i++) {
                                    var brand_city_link = (data.brands_top[i].is_shop) ? '/shops' : city_seo_link;
                                    brands += '<li><a href="' + webpath + brand_city_link + '/' + data.brands_top[i].seo_link + '" class="category-dropdown-brand-link" onclick="gaStats.trackStatsEvents(this)" title="' + data.brands_top[i].name + '">' + data.brands_top[i].name + '</a>';
                                }
                                $el.find('.category-dropdown-brands ul').first().html(brands);

                                if (data.brands_other.length) {
                                    brands = '';
                                    for (var i=0; i < data.brands_other.length; i++) {
                                        var brand_city_link = (data.brands_other[i].is_shop) ? '/shops' : city_seo_link;
                                        brands += '<li><a href="' + webpath +  brand_city_link + '/' + data.brands_other[i].seo_link + '" class="category-dropdown-brand-link" onclick="gaStats.trackStatsEvents(this)" title="' + data.brands_other[i].label + '">' + data.brands_other[i].label + '</a>';
                                    }
                                    $el.find('.category-dropdown-brands ul').eq(1).children('li').not(':last').remove();
                                    $el.find('.category-dropdown-brands ul').eq(1).prepend(brands);
                                }
                                else {
                                    $el.find('.category-dropdown-brands ul').eq(1).prev().remove();
                                    $el.find('.category-dropdown-brands ul').eq(1).remove();
                                }
                            }
                            else {
                                $el.find('.category-dropdown-brands').remove();
                            }

                            if (data.makes.length) {
                                for (var i=0; i < data.makes.length; i++) {
                                    var make_name_uri = data.products_page_v2 ? '/' + encodeURIComponent(data.makes[i].seo_link) : '/search' + encodeURIComponent('?search=' + data.makes[i].name);
                                    makes += '<li><a href="' + webpath + make_name_uri + '" class="category-dropdown-make-link" onclick="gaStats.trackStatsEvents(this)" "title="' + data.makes[i].name + '">' + data.makes[i].name + '</a>';
                                }
                                $el.find('.category-dropdown-makes ul').html(makes);
                            }
                            else {
                                $el.find('.category-dropdown-makes').remove();
                            }

                            for (var i=0; i < data.products.length; i++) {
                                products += '<li><a class="category-dropdown-product-link" onclick="gaStats.trackStatsEvents(this)" href="' + data.products[i].link + '" title="' + data.products[i].name + '">' + data.products[i].name + '</a>';
                            }
                            $el.find('.category-dropdown-products ul').html(products);

                            $(this).toggleClass("active-category");
                        }

                        $el.addClass("loaded");
                    }
                });
            }
        });
    }

    _init();

    _this.init = _init;
};