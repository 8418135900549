$(document).ready(function(e) {
    readyQueue.executeQueue();

    if(typeof siteSettings !== 'undefined' && siteSettings.site_header_redesign == "1"){
        var header = new Header();
        var upNavigation = new UpNavigation();
    }

    
    if(typeof isMobile !== 'undefined' && isMobile == false){
        /* Call tt-city.js funcitons */
        setTypeaheadCity();
        setTypeaheadBrand();

        if(typeof siteSettings !== 'undefined' && siteSettings.site_header_redesign == "1"){
            var headerCategories = new HeaderCategories();
        }
    }

    var campRedirect = new CampRedirect(pagePromotionSettings);
    campRedirect.init();

});