// Form Errors
if (typeof window.labels !== 'undefined') {
	var contactValidationMessages = {
		"email": {
			required: window.labels.contact_err_email
		},
		"name": {
			required: window.labels.contact_err_firstname
		},
		"family": {
			required: window.labels.contact_err_lastname
		},
		"subject": {
			required: window.labels.contact_err_subject
		},
		"msg": {
			required: window.labels.contact_err_message
		},
		"captcha": {
			required: window.labels.contact_err_captcha
		},
	};

	// Handle Validation and Ajax - Submit
	var contactFormValidate = $(".contact-form").validate({
		submitHandler: function(form) {
			/*$(form).ajaxSubmit({
				url: '/contact-us',
				method: 'POST'
			});*/

			// $(form).submit();

			form.submit();
		},
		messages: contactValidationMessages,
		onclick: true
	});


	$(".contact-form").on('keyup', function(e){

		var key = e.which;

		if (key===13) {
			// If key is "Enter"
			$(this).submit();
		};
	})
}