var UpLogin = function(params) {
    var _this = this,
        _loginPopupSel = '#up-login-popup',
        _hideBtnLoaderId;

    function _initFormSubmit() {
        $(document).on('submit', '#login-form', function(e) {
            e.preventDefault();
            var $form = $(this),
                formData = $form.serializeObject();

            $.ajax({
                type: "POST",
                url: '/profile/a_login',
                data: formData,
                beforeSend: _showBtnLoader,
                success: function(data) {
                    _hideBtnLoader();

                    if (data.status === true) {
                        $form.find('.up-login-error').text('');
                        if (window.inCashbackPromo) {
                            window.location.href = window.location.href + '?popup=step1';
                            window.location.reload();
                        } else {
                            if($('.up-login-form-wrap').closest('.mfp-content').length > 0){
                                window.location.reload();
                            }else if($('.up-login-form-wrap [name=redirect_to]').length > 0 && $('.up-login-form-wrap [name=redirect_to]').val() !== ''){
                                window.location.href = $('.up-login-form-wrap [name=redirect_to]').val();
                            }else{
                                window.location.href = '/profile/personal_data';
                            }
                        }
                    } else {
                        if (data.wrong_token === true) {
                            window.location.reload();
                        }
                        else {
                            $form.find('.up-login-error').text('* ' + data.msg);
                        }
                    }
                },

                dataType: 'json'
            });
        });
    }

    function _hideBtnLoader() {
        if(typeof _hideBtnLoaderId !== 'undefined'){
            clearTimeout(_hideBtnLoaderId);
        }

        _hideBtnLoaderId = setTimeout(function(){
            $('.up-btn-loader').removeClass('up-btn-loader-active');
        }, 500);
    }

    function _showBtnLoader() {
        $('.up-btn-loader').addClass('up-btn-loader-active');
    }

    function _initLoginPopup() {

    }

    function _openLoginPopup(openCallback, closeCallback) {
        $.magnificPopup.open({
            items: {
                src: _loginPopupSel,
                type: 'inline'
            },
            callbacks: {
                open: function(){
                    if(typeof openCallback !== 'undefined'){
                        openCallback();
                    }
                },
                close: function(){
                    if(typeof closeCallback !== 'undefined'){
                        closeCallback();
                    }
                }
            }
        });
    }

    function _closeLoginPopup() {

    }

    function _init() {
        _initFormSubmit();
    }

    _this.init = _init;
    _this.openLoginPopup = _openLoginPopup;
    _this.hideBtnLoader = _hideBtnLoader;
    _this.showBtnLoader = _showBtnLoader;
};