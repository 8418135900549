(function(activeClass, speed, timeout) {
    var

    /* Cache */

        $hero = $('.hero_section'),
        $tabs = $('.hero_tab'),
        $slides = $('.hero_slide'),
        $rotation = 0;

    if ($('.hero_slider').hasClass("rotation-start")) {
        $rotation = 1;
    }

    var cache = {};

    /* Methods */

    function _start() {
        timeout = setTimeout(_rotate, speed);
    }

    function _stop() {
        clearTimeout(timeout);
    }

    function _fade($current, $target) {
        $current.stop().fadeOut(333);
        $target.stop().fadeIn(333);
    }

    function _slide($current, $target) {
        // Init
        var init = cache.slide ? cache.slide : false;

        function _init() {
            $slides.css({ marginLeft: "-100%", display: 'block' });
            $slides.eq(0).css({ marginLeft: "0%" });
        }
        if (!init) {
            cache.slide = true;
            _init();
        };

        // Hide Current
        $current.css({ marginLeft: "0%" });
        $current.stop().animate({
            marginLeft: "-100%"
        }, 500);

        //Show Target
        $target.css({ marginLeft: "100%" });
        $target.stop().animate({
            marginLeft: "0%"
        }, 500);
    }

    function _rotate() {
        if ($rotation == 1) {
            var

                $currentTab = $tabs.filter('.' + activeClass),
                index = $tabs.index($currentTab) + 1;

            if (index >= $tabs.length) {
                index = 0;
            };

            _activateSlide($slides.eq(index));
            _activateTab($tabs.eq(index));

            _stop();
            _start();
        }
    }

    function _activateTab($tab) {
        $tabs.removeClass(activeClass);
        $tab.addClass(activeClass);
    }

    function _activateSlide($targetSlide) {
        var $currentSlide = $slides.filter('.' + activeClass);

        //_fade($currentSlide, $targetSlide);

        _slide($currentSlide, $targetSlide);

        // Deactivate Current Tab
        $currentSlide.removeClass(activeClass);

        // Activate Target Tab
        $targetSlide.addClass(activeClass);
    }

    /* Events */
    $tabs.on('click', function() {
        if ($(this).hasClass(activeClass))
            return;

        _activateSlide($slides.eq($tabs.index(this)));
        _activateTab($(this));
    });

    $hero.on('mouseover', function() {
        _stop();
    });

    $hero.on('mouseleave', function() {
        _start();
    });

    /* Init */
    _start();



})('active', 6000);


$(document).ready(function() {
    if ($('.hero_tabs').length > 0) {
        var boxOffset = $(".hero_tabs").offset().left;
        $(document).on('click', '.hero_inner_tabs .clickHero', function(e) {
            var indexElement = $(".clickHero").index(this);
            var nextElementPosition = ($(this).outerWidth(true) + $(this).offset().left) - $(".hero_tabs").offset().left;
            var scrollToNext = false;
            var lastElement = false;
            var prevElementOffset = $(this).offset().left - boxOffset;
            var prevElement = false;

            if ($(this).prev().length) {
                var prevElementOffset = $(this).prev().offset().left - boxOffset;
                prevElement = true;
            }

            if ($(this).next().length) {
                nextElementPosition = ($(this).next().outerWidth(true) + $(this).next().offset().left) - boxOffset;
                scrollToNext = true;
            } else {
                nextElementPosition = ($(this).outerWidth(true) + $(this).offset().left) - boxOffset;
                scrollToNext = true;
                lastElement = true;
            }

            var offsetElementInBox = nextElementPosition > $('.hero_tabs').width() - 20;
            if (offsetElementInBox && scrollToNext) {
                var scrollPX = $(".hero_tabs").scrollLeft() + $(this).next().outerWidth(true) + 40;
                if (lastElement || $(this).next().outerWidth(true) > $(this).outerWidth(true)) {
                    scrollPX = $(".hero_tabs").scrollLeft() + $(this).outerWidth(true);
                }
                scrollTabs(scrollPX)
            } else if (prevElementOffset < 0) {
                var scrollPX = $(".hero_tabs").scrollLeft() - ($(this).outerWidth(true) + 40);
                if (prevElement) {
                    scrollPX = ($(".hero_tabs").scrollLeft() - ($(this).prev().outerWidth(true) + 50))
                }
                scrollTabs(scrollPX)
            }
        });
        // show arrrows 

        function showWhenNot(){
            $(".hero_slider").hide();
                setTimeout(function(){
                     $(".hero_slider").show();
                },10)
        }

        function showArrows() {
            var firstEl = $(".clickHero").first();
            var lastEl = $(".clickHero").last();
            
            if(firstEl.length == 0){
                return;
            }

            if (firstEl.offset().left < $(".hero_tabs").offset().left) {
                $('.hero_tabs_prev_arrow').show();
            } else {
                $('.hero_tabs_prev_arrow').hide();
            }
            if ((lastEl.outerWidth(true) + lastEl.offset().left) - $(".hero_tabs").offset().left > $('.hero_tabs').width()) {
                $('.hero_tabs_next_arrow').show();
            } else {
                $('.hero_tabs_next_arrow').hide();
            }
        }


        var IntervalScroll = '';
        $('.hero_tabs_arrows span').mouseover(function(e) {
            var nextEL = $(this).hasClass('hero_tabs_next_arrow');
            IntervalScroll = setInterval(function() {
                if (nextEL) {
                    scrollTabs($(".hero_tabs").scrollLeft() + 20)
                } else {
                    scrollTabs($(".hero_tabs").scrollLeft() - 20)
                }
            }, 100)

        })
        $('.hero_tabs_arrows span').mouseout(function(e) {
            clearInterval(IntervalScroll)
        })


        function scrollTabs(scrollPX) {
            $('.hero_tabs').stop().animate({
                scrollLeft: scrollPX
            }, 200, function() {
                showArrows();
            });
        }



        $('.hero_tabs').outerWidth($('.hero_navigation').outerWidth() - $('.hero_title').outerWidth() - 80);
        $('.hero_tabs_arrows').outerWidth($('.hero_navigation').outerWidth() - $('.hero_title').outerWidth() - 80);
        $('.hero_tabs_arrows').css('left', $('.hero_title').outerWidth(true) + 15);
        showArrows();
        showWhenNot();
    }
})