$('.letters').on("click", ".letters-tab", function(e)
{
	e.preventDefault();

	var id = $(this).data("tab"); console.log("id", id);

	var $tab = $(this);	console.log("$tab", $tab);
	var $allTabs = $tab.parent().find('.letters-tab'); console.log("$allTabs", $allTabs);

	// Set Active Tab Link
	$allTabs.removeClass("letters-tab-active");
	$tab.addClass('letters-tab-active');

	var $panels = $tab.parents(".letters").find(".letters-panels");

	// Set Active Tab Panel
	$panels.find(".letters-panel").hide();
	$panels.find("."+id).show();
});


$('.letters').on("click", ".letter-link", function(e)
{
	e.preventDefault();

	var id = $(this).attr('href');

	$(".scroll-letters").find(".active").removeClass("active");
	$(id).addClass("active");

	$(".scroll-letters").mCustomScrollbar("scrollTo", id, { scrollInertia: 200 } );
});