var Header = function(params) {
    var _this = this,
        _firstSandwitchClick = true;

    function _markNotificationsAsRead($el, loginToken) {
        $.post('/profile/read_notifications', { login_token: loginToken, read_notifications: true }, function(data) {
            if (data.status == true) {
                $el.find('.sh-n-count').remove();
            }
            // console.log("read_notifications data.status", data.status);
        }, 'json');
    }


    function _handleClickEvents() {
        if(!isMobile){
            $(document).on('click', '#sh-nav-menu', function(e) {
                var $el = $(this),
                    target = $el.data('target');

                if (_firstSandwitchClick) {
                    $('.sh-cat-nav .sh-cat-nav-item:first').trigger('mouseenter');
                    _firstSandwitchClick = false;
                }

                $el.toggleClass('open');
                $(target).toggleClass('open');

            });
        }

        $(document).on('click', '.sh-wrap .sh-right-icon', function(e) {
            var $el = $(this),
                target = $el.data('target');

            if ($el.hasClass('sh-profile-login') === true) {
                return;
            }

            if ($el.hasClass('sh-notification-icon') && $el.find('.sh-n-count').length > 0) {
                var loginToken = $el.find('[name=login_token]').val();
                _markNotificationsAsRead($el, loginToken);
            }

            $('.sh-wrap .sh-right-icon.open').not($el).removeClass('open').each(function(inx, item) {
                $('.sh-wrap').find($(this).data('target')).removeClass('open').delay(200).hide(0, function() { $(this).removeClass('sh-edge'); });
            });

            if ($el.hasClass('open')) {
                $el.removeClass('open');
                $('.sh-wrap').find($el.data('target')).removeClass('open').delay(200).hide(0, function() { $(this).removeClass('sh-edge'); });
            } else {
                $el.addClass('open');
                $('.sh-wrap').find($el.data('target')).show(0, function() {
                    _checkIfPopupElemIsOutsideWindow($(this));
                    $(this).addClass('open');
                });
            }

        });

        $(document).on('click', '.sh-profile-login', function(e) {
            e.preventDefault();
            
            if($(this).closest('.sh-wrap').length){
                $(this).removeClass('open');
            }

            if (page_name !== 'login') {
                upLogin.openLoginPopup();
            }
        });

        if (typeof isMobile !== 'undefined' && isMobile === true) {
            $(document).on('click', '.sh-profile-navigation.open .sh-close-navigation', function(e) {
                $('.sh-wrap .sh-profile-icon').removeClass('open');
                $('.sh-wrap .sh-profile-navigation').removeClass('open').delay(300).parent().removeClass('open').hide(0);
                $('html').removeClass('html-block-scroll');
            });

            $(document).on('click', '.sh-wrap .sh-profile-icon:not(.open)', function(e) {
                if (page_name === "login" || $(this).hasClass('sh-profile-login')) {
                    e.preventDefault();
                } else {
                    $('html').addClass('html-block-scroll');
                }
            });
        } else {
            $(document).on('click', '.sh-wrap .sh-profile-name-label', function(e) {
                $('.sh-wrap .sh-profile-icon').click();
            });
        }
    }

    function _handlePopupsClose() {
        var event = isMobile ? "touchstart" : "click";

        $(document).on(event, function(e) {
            var $el = $(e.target);

            /* Handle mock trigger of mfp subscribe click, when mouse leaves the screen */
            if ($el.hasClass('mfp-close') === true ||
                $el.hasClass('newsletter-popup') === true) {
                return;
            }

            /* Close categories menu */
            if ($el[0].id !== 'sh-nav-menu' &&
                $el.closest('#sh-nav-menu').length === 0 &&
                $el.closest('.sh-cat-wrap').length === 0 &&
                $('.sh-cat-wrap.open').length > 0) {
                $('#sh-nav-menu').click();
                $('html').removeClass('html-block-scroll');
            }

            if ($el.hasClass('sh-right-icon') === false &&
                $el.hasClass('sh-profile-name-label') == false &&
                $el.closest('.sh-right-icon').length === 0 &&
                $el.closest('.sh-popup-elem').length === 0 &&
                $('.sh-wrap .sh-right-icon.open').length > 0
            ) {
                $('.sh-wrap .sh-right-icon.open').click();
                $('html').removeClass('html-block-scroll');
            }
        });
    }

    function _handleWindowResize() {
        $(window).on('resize', function(e) {
            $('.sh-wrap .sh-popup-elem.open').each(function(inx, item) {
                _checkIfPopupElemIsOutsideWindow($(item), '.sh-container');
            });
        });
    }

    function _checkIfPopupElemIsOutsideWindow(target, containerSel) {
        var targetSel = $(target), //$(this).data('target'),
            containerSel = containerSel || 'body',
            edgeClass = "sh-edge";


        if ($(targetSel).length) {
            var elm = $(targetSel),
                off = elm.offset(),
                l = off.left,
                w = elm.outerWidth(true),
                docW = $(containerSel).outerWidth(true);

            var isEntirelyVisible = (l + w <= docW);

            if (!isEntirelyVisible) {
                $(targetSel).addClass(edgeClass);
                // console.log("add class" + edgeClass, l, w, (l + w), docW, (l + w <=docW));
            } else {
                $(targetSel).removeClass(edgeClass);
                // console.log("remove class" + edgeClass, l, w, (l + w), docW, (l + w <=docW));
            }
        } else {
            // console.log('target.length is 0');
        }
    }

    function _addMonthNamesForNotifications() {
        $('.sh-notifications-wrap .sh-n-date').each(function(inx, item) {
            var date = new Date($(this).data('date')),
                day = date.getDate(),
                month = date.getMonth() + 1; // otherwise it is 1 month less than actual

            $(this).text(dayNames[date.getDay()] + ", " + (day > 9 ? day : '0' + day) + "." + (dayNames > 9 ? month : '0' + month));
        });
    }

    function _removeDoubleTapForIphone() {
        $(".up-nav-item").on("touchstart", function(e) {
            var el = $(this);
            var link = el.attr("href");
            window.location = link;
        });
    }

    function _markAsReadMobile() {
        if (typeof isMobile !== 'undefined' && isMobile == true && page_name !== "brochure_view" && $('.sh-wrap .sh-right-icon').length > 0) {
            var loginToken = $('[name=login_token]').val();
            _markNotificationsAsRead($('.up-notif-mobile'), loginToken);
        }
    }

    function headerMobileOnActiveSmartbanner() {
        var addEventsCount = 0;
        function scrollHandler() {
            if($('#af-smart-banner').length !== 1 || !$('.header_wrapper').length) {
                return
            }
            addEventsCount++;
            var smartbannerSelector = document.querySelector('#af-smart-banner');
            var smartbannerHeight = smartbannerSelector.offsetHeight;
            var headerSelector = document.querySelector('.header_wrapper');

            $(".page.mt85:not(.homepage)").css("margin-top", "0");

            if (window.pageYOffset < smartbannerHeight && smartbannerSelector.style.display !== "none") {
                headerSelector.style.position = "relative";
            } else {
                headerSelector.style.top = '0px';
                headerSelector.style.position = "";
                $(".page.mt85").css("margin-top", "");
            }
    
            if (addEventsCount === 1) {
                $('.afb-close-container').on('click', function(e){
                    headerSelector.style.top = '0px';
                    headerSelector.style.position = "";
                    $(".page.mt85").css("margin-top", "85px");
                    window.removeEventListener('scroll', scrollHandler);
                });
            }
        }

        scrollHandler();
        window.addEventListener('scroll', scrollHandler);
    }

    function _init() {
        _markAsReadMobile();
        // _removeDoubleTapForIphone();
        _handleClickEvents();
        _handleWindowResize();
        _handlePopupsClose();
        _addMonthNamesForNotifications();
        headerMobileOnActiveSmartbanner();

        $(".mobile-device .secondary_header .swiper-button-next").addClass("animated");

        $(".mobile-device .secondary_header").on("touchend", function(){
            $(this).find(".swiper-button-next").removeClass("animated");
        });
    }

    _init();

    _this.init = _init;
};