if (typeof brochure_impression_statistics !== "undefined" && brochure_impression_statistics) {
    var BrochureImpressionStat = function(brochureClass){
        var _this = this,
            //pages = isMobile ? $(".catalog") : $(".catalog .catalog-image"),
            //sentBrochureImpressionsArray = [],
            pendingBrochureImpressionsArray = [],
            uuid;
        
        window.sentBrochureImpressionsArray = [];

        function createUUID() {
            return 'xxxxxxxx-xxxx-xxxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
                var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
                return v.toString(16);
            });
        };

        function setCookie(name, value, days) {
            var d = new Date;
            d.setTime(d.getTime() + 24*60*60*1000*days);
            document.cookie = name + "=" + value + ";path=/;expires=" + d.toUTCString();
        }

        function getCookie(name) {
            var value = "; " + document.cookie;
            var parts = value.split("; " + name + "=");
            if (parts.length == 2) return parts.pop().split(";").shift();
        }

        if (document.cookie.indexOf('uid=') != -1) {
            uuid = getCookie('uid');
        } else {
            uuid = createUUID();
            setCookie('uid', uuid, 365);
        }

        // missing forEach on NodeList for IE11
        if (window.NodeList && !NodeList.prototype.forEach) {
            NodeList.prototype.forEach = Array.prototype.forEach;
        }

        function debounce(func, wait, immediate) {
            var timeout;
            return function() {
                var context = this, args = arguments;
                var later = function() {
                    timeout = null;
                    if (!immediate) func.apply(context, args);
                };
                var callNow = immediate && !timeout;
                clearTimeout(timeout);
                timeout = setTimeout(later, wait);
                if (callNow) func.apply(context, args);
            };
        };

        function sendImpressions() {
            var device = (isMobile || isTablet) ? 'mobile' : 'desktop';

            $.ajax({
                type: 'POST',
                url: '/ajx/ajax.file.php',
                contentType: "application/json",
                data: JSON.stringify({
                    impressions: pendingBrochureImpressionsArray,
                    device: device,
                    userUuid: uuid,
                    source: window.location.href,
                    fn: "brochureImpressions"
                }),
                dataType: 'json',
                success: function(data){
                    
                },
                error: function(err){}
            });

            pendingBrochureImpressionsArray = [];
        }

        function checkPage() {
            var pages = $(brochureClass);
            console.log(pages)

            $.each(pages, function(i, page) {
                // half way through the image
                var scrollInPage = (window.pageYOffset + window.innerHeight);
                // bottom of the image
                var imageBottom = $(page).offset().top + $(page).height();
                var isHalfShown = scrollInPage > $(page).offset().top;
                var isNotScrolledPast = window.pageYOffset < (imageBottom - 50);

                var brochureId = Number($(page).attr('data-brochure-id'));
                
                if (isHalfShown && isNotScrolledPast) {
                    if(sentBrochureImpressionsArray.indexOf(brochureId) === -1 && brochureId > 0) {
                        sentBrochureImpressionsArray.push(brochureId);
                        pendingBrochureImpressionsArray.push(brochureId);
                    }
                }
            });
            
            if (pendingBrochureImpressionsArray.length) {
                sendImpressions();
            }

        }

        function init() {
            checkPage();
            window.addEventListener('scroll', debounce(checkPage, 100, true));
        }

        _this.init = init;
    }

    switch (page_name) {
        case "city_detail":
            var brochureImpressionStat = new BrochureImpressionStat(isMobile ? ".catalog" : ".catalog .catalog-image");
            break;
        case "brand_city":
            var brochureImpressionStat = new BrochureImpressionStat(isMobile ? ".vr-catalog__image, .ga-brochure-catalog" : ".vr-catalog__image, .catalog-image");
            break;
        case "brochure_view":
            var brochureImpressionStat = new BrochureImpressionStat(isMobile ? ".vr-catalog__image" : ".vr-catalog__image");
            break;
        case "campaign_index":
            var brochureImpressionStat = new BrochureImpressionStat(".vr-catalog__image, .frame__aside .info-box");
            break;
        case "brand_detail":
            var brochureImpressionStat = new BrochureImpressionStat(".catalog-image:last");
            break;
        case "brands":
            var brochureImpressionStat = new BrochureImpressionStat(".catalog-image:last");
            break;     
        case "category_detail":
            var brochureImpressionStat = new BrochureImpressionStat(".catalog-image");
            break;    
        case "categories":
            var brochureImpressionStat = new BrochureImpressionStat(".catalog");
            break;       
        default: 
            break;
    }

    brochureImpressionStat.init();
}
