if (typeof offerista_tracking !== "undefined" && offerista_tracking) {
    var OfferistaTracking = function() {
        var _this = this,
            dateObj = new Date(),
            data = {},
            uuid;

        function createUUID() {
            return 'xxxxxxxx-xxxx-xxxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
                var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
                return v.toString(16);
            });
        };

        function setCookie(name, value, days) {
            var d = new Date;
            d.setTime(d.getTime() + 24*60*60*1000*days);
            document.cookie = name + "=" + value + ";path=/;expires=" + d.toUTCString();
        }

        function getCookie(name) {
            var value = "; " + document.cookie;
            var parts = value.split("; " + name + "=");
            if (parts.length == 2) return parts.pop().split(";").shift();
        }

        if (document.cookie.indexOf('uid=') != -1) {
            uuid = getCookie('uid');
        } else {
            uuid = createUUID();
            setCookie('uid', uuid, 365);
        }

        data.fn = 'offeristaTracking';
        data.trackings = [{
                client: navigator.userAgent,
                source: window.location.origin + window.location.pathname,
                userUuid: uuid,
                createdAt: dateObj.toISOString()
        }];

        function sendTracking(data) {
            data.trackings = data.trackings.filter(function(el){
                if (el.type === "SCREEN_VIEW") {
                    return data.trackings;
                }
                if (typeof el.objectId !== "undefined" && el.objectId > 0) {
                   return el;
                }
            });

            if (data.trackings.length) {
                $.ajax({
                    url: '/ajx/ajax.file.php',
                    type: 'POST',
                    dataType: 'json',
                    contentType: "application/json",
                    data: JSON.stringify(data),
                    success: function(pixels){
                        if (typeof pixels !== 'undefined' && pixels.length) {
                            $.each(pixels, function(index, value) {
                                var trackingBugImgPixel = document.createElement('img');
                                trackingBugImgPixel.height = '1';
                                trackingBugImgPixel.width = '1';
                                trackingBugImgPixel.style = 'border-style:none;';
                                trackingBugImgPixel.alt = '';
                                trackingBugImgPixel.src = value.replace('%%CACHEBUSTER%%', Date.now());
                            });
                        }
                    },
                    error: function(err){
                    }
                });
            }
        }

        function trackingPixel(trackingUrl) {
            var trackingBugImgSrc = trackingUrl;
            var trackingBugImgTag = document.createElement('img');
            
            trackingBugImgTag.height = '1';
            trackingBugImgTag.width = '1';
            trackingBugImgTag.style = 'border-style:none;';
            trackingBugImgTag.alt = '';
            trackingBugImgTag.src = trackingBugImgSrc.replace('%%CACHEBUSTER%%', Date.now());
        }

        function commonTrack() {
            data.trackings[0].type = "SCREEN_VIEW";
            data.trackings[0].screenId = page_name;

            sendTracking(data);
        }

        _this.commonTrack = commonTrack;
        _this.sendTracking = sendTracking;
        _this.trackingPixel = trackingPixel;
        _this.data = data;
        _this.createUUID = createUUID;
    }

    var screenViewTrack = new OfferistaTracking();
    screenViewTrack.commonTrack();

    if (page_name === "brand_city" || page_name === "brands" || page_name === "brand_detail") {
        var companyClickTrack = new OfferistaTracking();
        
        companyClickTrack.data.trackings[0].type = "COMPANY_CLICK";

        if (page_name === "brand_city") {
            companyClickTrack.data.trackings[0].objectId = Number($(".brand_info_header").attr("data-offerista-brand"));
        } else {
            companyClickTrack.data.trackings[0].objectId = Number($(".js-brand-title").attr("data-offerista-brand"));
        }

        if (companyClickTrack.data.trackings[0].objectId !== 0) {
            companyClickTrack.sendTracking(companyClickTrack.data);
        }
    }
}
