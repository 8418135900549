$(function(){
  var $logo = $('.main_navigation .brand img');

  if($logo.length){
    var logoSrc = $logo.attr('src');

    if(logoSrc.indexOf(".gif") > -1){
      var timeout = 0;
      var animate = true;

      $logo.on("mouseover", function(e){
        if(animate){
          $logo.attr('src', logoSrc);
          animate = false;

          // the animation runs for 7 seconds
          setTimeout(function(){
            animate = true;
          }, 7000);
        }else{
          // console.log("Animataion hasn't finished...");
        }
      });
    }
  }
});