var ReadyQueue = function(params) {
    var _this = this;

    function _init() {

    }

    _this.init = _init;
    _this.methodQueue = [];
    _this.executeQueue = function() {
        $.each(_this.methodQueue, function(inx, item) {
            if (typeof item.method === 'function') {
                item.method();
            }
        });
    };
};

var readyQueue = new ReadyQueue();