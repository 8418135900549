(function(activeClass, speed, timeout){
	var

	/* Cache */

	$container = $('.side-slider-content'),
	$counter = $('.side-slider-title .pull-right span'),
	$slides = $container.find('.slide');

	var 

	count = 1,
	isInit = false, 
	margin = 4;

	var position = { prev: 0, active: 0, next: 0 };

	/* Methods */

	function _start()
	{
		timeout = setTimeout(_rotate, speed);
	}

	function _stop()
	{
		clearTimeout(timeout);
	}

	function _init()
	{
		// Cache positions
		position.active = _getPositionActive();
		position.prev = _getPositionPrev();
		position.next = _getPositionNext();

		// Order Slides
		$slides.eq(0).css({ top: position.active }); // Active
		$slides.eq($slides.length-1).css({ top: position.prev }); // Prev
		$slides.eq($slides.length-2).css({ top: -179*2-33 }); // Prev
		$slides.eq(1).css({ top: position.next }); // Next

		// Update counter
		$counter.text(count);
	}

	function _getPositionActive() {
		return ( $container.height() - $slides.eq(0).height() ) / 2;
	}

	function _getPositionPrev() {
		var $slide = $slides.eq(0);

		return -($slide.height()) + _getPositionActive() - margin;
	}

	function _getPositionNext() {
		var $slide = $slides.eq(0);

		return ($slide.height()) + _getPositionActive() + margin;
	}

	function _slide($current, $target)
	{	
		// Init
	
		if (!isInit) { isInit = true; _init(); return; };

		var	$current = $slides.filter('.'+activeClass);
		var $next = $current.next();
		var $prev = $current.prev();

		if ($next.length!==1) { $next = $slides.eq(0); };
		if ($prev.length!==1) { $prev = $slides.eq($slides.length-1); };

		var $last = $prev.prev();
		if ($last.length!==1) { $last = $slides.eq($slides.length-1); };

		$slides.hide();

		// Move Active to Next
		$current.show().stop().animate({
			top: position.next
		}, 333);
		$current.removeClass(activeClass);

		// Exit Next
		$next.show().stop().animate({
			top: 179*2+33
		}, 333, function(){
			$next.css({ top: -179*2-33 });
		});
		
		// Move Prev to Active
		$prev.show().stop().animate({
			top: position.active
		}, 333);
		$prev.addClass(activeClass);

		// Move Last to Prev
		$last.show().stop().animate({
			top: position.prev
		}, 333);

		// Update counter
		count = count+1;
		if (count > $slides.length) { count = 1; };
		$counter.text(count);
		
	}

	function _rotate()
	{
		var 

		$currentSlide = $slides.filter('.'+activeClass),
		index = $slides.index($currentSlide)+1;

		if (index>=$slides.length) {
			index = 0;
		};

		_activateSlide($slides.eq(index));

		_stop();
		_start();
	}

	function _activateSlide($targetSlide)
	{
		var $currentSlide = $slides.filter('.'+activeClass);

		_slide($currentSlide, $targetSlide);
	}

	/* Events */

	$container.on('mouseover', function(){
		_stop();
	});

	$container.on('mouseleave', function(){
		_start();
	});


	/* Init */
	_start();

})('active', 2000);