    function ColorLuminance(hex, lum) {

        if (hex === "undefined") {
            return;
        }
        // validate hex string
        hex = String(hex).replace(/[^0-9a-f]/gi, '');
        if (typeof hex !== "string") {
            return;
        }
        if (hex.length < 6) {
            hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
        }
        lum = lum || 0;

        // convert to decimal and change luminosity
        var rgb = "#",
            c, i;
        for (i = 0; i < 3; i++) {
            c = parseInt(hex.substr(i * 2, 2), 16);
            c = Math.round(Math.min(Math.max(0, c + (c * lum)), 255)).toString(16);
            rgb += ("00" + c).substr(c.length);
        }

        return rgb;
    }
    $(document).ready(function() {
        if ($("#darkenColor").length > 0) {
            var color = $("#darkenColor").val();
            if (color) {
                var border = ColorLuminance("#" + color, -0.2);
                $(".darken").css({
                    "border": "1px solid " + border,
                    "border-bottom": "5px solid " + border
                });
            }
        }
        // 2 because in view there is also a popup
        if ($("#darkenColor2").length > 0) {
            var color2 = $("#darkenColor2").val();
            if (color2) {
                var border2 = ColorLuminance("#" + color2, -0.2);
                $(".darken2").css({
                    "border": "1px solid " + border2,
                    "border-bottom": "5px solid " + border2
                });
            }

        }

    });