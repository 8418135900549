$(function() {
    $(document).on('click', '#brandpage .list-cities li a, .city-link-history a, .ui-menu-item a, .map-cities-list li a, #brandmap .tt-link', function(){
        document.cookie.indexOf('geo') != -1 ? setCookie('geo', 0, -1) : '';
    });
});

function setCookie(name, value, days) {
    var d = new Date;
    d.setTime(d.getTime() + 24*60*60*1000*days);
    document.cookie = name + "=" + value + ";path=/;expires=" + d.toUTCString();
}