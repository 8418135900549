var CampRedirect = function(pagePromotionSettings){
    var _this = this,
        _defaultSystemForbiddenUrls = [
            'profile'
        ];

    function _isRedirectNeeded(){
        if(typeof pagePromotionSettings !== 'undefined'){
            return true;
        }

        return false;
    }

    function _handleClick(){
        $(document).on('click', 'a, .view-buttons-wrap.next-brochure', function(e){
            var $el = $(this),
                nextPageUrl = $el.attr('href');
            
            if($el.hasClass('next-brochure')){
                nextPageUrl = viewSettings.relatedBrochure.brochure_link;
            }

            if(_isDomainBlackListed(nextPageUrl) === true){
                return;
            }

            if(_skipSpecialCase($el)){
                return;
            }

            _checkAttrs($el);

            nextPageUrl = pagePromotionSettings.link + '&redirect_url=' + encodeURIComponent(nextPageUrl);

            window.open(nextPageUrl);
        });
    }

    function _isDomainBlackListed(nextPageUrl){
        var isBlackListed = false;

        if(typeof nextPageUrl !== 'undefined' && nextPageUrl !== ''){
            pagePromotionSettings.forbidden_urls = pagePromotionSettings.forbidden_urls.concat(_defaultSystemForbiddenUrls);

            $.map(pagePromotionSettings.forbidden_urls, function(val, inx){
                if(nextPageUrl.indexOf(val.toLowerCase()) > -1){
                    isBlackListed = true;
                }
            });
        }

        return isBlackListed;
    }

    function _checkAttrs($el){
        if(typeof $el.attr('target') !== 'undefined'){
            $el.removeAttr('target');
        }
    }

    function _skipSpecialCase($el){
        var skipEl = false;

        if($el.hasClass('close-cookies-bar')
            || $el.hasClass('link-cookies-bar')
            || $el.hasClass('hidden')
            // || $el.hasClass('sh-site-logo')
            || $el.hasClass('vr-mobile-nav__btn')
            || $el.hasClass('vr-cat-nav-item')
            || $el.hasClass('vr-header__label-btn_close')
            || $el.hasClass('vr-header__logo')
            || $el.parent().hasClass('vr-related-bottom__btn')
            || $el.closest('.menu-tabs').length > 0
            || $el.closest('.navigation-tabs').length > 0
            || $el.closest('.brand-tabs').length > 0
            || $el.hasClass('social-link')
            || $el.hasClass('open-sidemenu')
            || $el.hasClass('close-sidemenu')
            || $el.hasClass('open-select-city-global')
            || $el.hasClass('close-select-city-global')
            || $el.hasClass('open-search')
            || $el.hasClass('close-search')

            ){
            skipEl = true;
        }

        return skipEl;
    }
    
    function _redirectTo(){
        
    }        

    function _init(){
        var isRedirectNeeded = _isRedirectNeeded();

        if(isRedirectNeeded === true){
            _handleClick();
        }

        return;
    }

    _this.init = _init;
};