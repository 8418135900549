function setTypeaheadCity() {
    var $tt = $('input.typeahead-devs').not('.typeahead-brand');

    var path = current_path ? current_path : '';

    // single dataset
    $tt.typeahead({
        name: 'cities',
        limit: 8,
        local: typeof(window.typeaheadCities) === "object" ? window.typeaheadCities : typeaheadDummy,
        //prefetch: template.typeahead.domain+"/main/citysearch",
        remote: template.typeahead.domain + "/main/citysearch/%QUERY",

        template: '<a class="tt-link" href="{{link}}' + path + '">{{value}}</a>',

        engine: Hogan
    });

    $tt.on("blur", function(e) {
        setTimeout(function() {
            $('.hero-search .select-typeahead .tt-suggestion').remove();
        }, 400);
    });

    $tt.on("typeahead:selected", function(e, city) {
        window.location.href = city.link;
    });

    $tt.on("typeahead:initialized ", function() {
        translateTypeaheadCities();

        if ($tt.parents('.select-typeahead.main_select')) {
            feedTypeahead();
        };

        var $ttDropDown = $(this).parent().find('.tt-dropdown-menu');

        $ttDropDown.mCustomScrollbar();
    });

    var ttTimeout;
    $tt.on('keyup', function() {
        var value = $tt.val(),
            t,
            $ttDropDown = $(this).parent().find('.tt-dropdown-menu');

        ifInputEmptyReset(value);

        clearTimeout(ttTimeout);
        ttTimeout = setTimeout(function() {
            ifInputFullAndNoResults(value, $ttDropDown);
        }, 500);
    });
}

function setTypeaheadBrand() {
    var $tt_brand = $('input.typeahead-brand').not('.typeahead-devs');

    var path = current_path ? current_path : '';

    var brandSeoLink = window.brand_seo_link ? window.brand_seo_link : '12';


    // single dataset
    $tt_brand.typeahead({
        name: 'cities-brand',
        limit: 5,

        //prefetch: template.typeahead.domain+"/main/citysearch",
        remote: template.typeahead.domain + "/main/citysearch/%QUERY" + "/" + brandSeoLink,

        template: '<a class="tt-link" href="{{link}}' + path + '">{{value}}</a>',

        engine: Hogan
    });

    $tt_brand.on("blur", function(e) {
        setTimeout(function() {
            $('.hero-search .select-typeahead .tt-suggestion').remove();
        }, 400);
    });

    $tt_brand.on("typeahead:selected", function(e, city) {
        window.location.href = city.link;
    });

    $tt_brand.on("typeahead:initialized ", function() {

        var $ttDropDown = $(this).parent().find('.tt-dropdown-menu');

        if ($('.select-typeahead').is(".select-hidden-mode")) {
            return
        };

        /*feedTypeahead();*/
        // $(".tt-dropdown-menu").mCustomScrollbar();

        $ttDropDown.mCustomScrollbar();
    });

    var ttTimeout;
    $tt_brand.on('keyup', function() {
        var value = $tt_brand.val(),
            t,
            $ttDropDown = $(this).parent().find('.tt-dropdown-menu');

        ifInputEmptyReset(value);

        clearTimeout(ttTimeout);
        ttTimeout = setTimeout(function() {
            ifInputFullAndNoResultsBrand(value, $ttDropDown);
        }, 500);
    });
}