var SiteNotifications = function() {

    var notificationSelector;
    var body = document.querySelector('body');
    var visualisationTypes = {
    
        showHideOnScroll: function() {
            window.addEventListener('scroll', onScroll);
        },
        
        showAfterSeconds: function(seconds) {
            setTimeout(function(){
                showNotification();
            }, seconds*1000);
        },
        
        showOnMouseLeave: function() {
            if(!isMobile) {
                body.addEventListener('mouseleave', showNotification);
            }
        },
    
        viewAfterPage: function(page) {
            showAfterPage = page.split("-").length > 1 ? parseInt(page.split("-").pop()) : parseInt(page.split("=").pop());
            if(page_name === 'brochure_view') {
                window.addEventListener('scroll', visiblePage);
            }
        }
    }

    function checkForVideoBanner() {
        if ($("script[src^='https://galaxy.up.video']").length && isMobile) {
            if (document.cookie.indexOf('upVideoImpressions') === -1 || document.cookie.indexOf('upVideoImpressions=1') >= 0) {
                return false
            } else {
                return true
            }
        } else {
            return true
        }
    }

    function showNotification() {
        notificationSelector.removeClass("hide");
    }

    function onScroll() {
        if(window.pageYOffset > 200) {
            showNotification();
        } else {
            notificationSelector.addClass("hide");
        }
    }

    function visiblePage() {
        var pageNumber = parseInt(document.querySelector(".page_counter").dataset.pageId.split("-").pop());

        if(showAfterPage < pageNumber) {
            showNotification();
            window.removeEventListener('scroll', visiblePage);
        }
    }

    function closeNotification(visualisationType) {
        $(".notification").on("click", function(e){
            notificationSelector.addClass("hide");
            if(visualisationType === 2) {
                window.removeEventListener('scroll', onScroll);
            }
            if(visualisationType === 3) {
                body.removeEventListener('mouseleave', showNotification);
            }
        });
    }

    function notificationEvents() {
        var device = isMobile ? "mobile" : "desktop";
        var notification = document.querySelector(".notification");
        
        function gaEvent(action, label) {
            ga('send', 'event', 'left-notification-on-site', action, label);
            notification.removeEventListener("click", gaHandler);
        }
        
        function gaHandler(e){

            if(e.target.dataset.action !== "close") {
                gaEvent(device, notification.dataset.name);
                
                $.ajax({
                    type: "POST",
                    url: "/main/display_notification",
                    data: {id: notification.dataset.id, fn: 'click'},
                    dataType: 'json',
                    success: function(data){
                    },
                    error: function(err){
                        console.log(err);
                    }
                });
            } else {
                gaEvent(device + " close", notification.dataset.name);
            }
        }
        
        notification.addEventListener("click", gaHandler);
    }

    function getActiveNotification() {
        if (['city_detail','brand_detail','brand_city','brochure_view','brands','campaign_index'].indexOf(page_name) > -1) {
            var page = page_name == 'brands' ? 'brand_city' : page_name;
            var brand_id = typeof brandId  !== 'undefined' ? brandId : null;

            $.ajax({
                type: "POST",
                url: "/main/display_notification",
                data: {page: page, city_id: city_id, brand_id: brand_id, client_popup: $('.ad-popup-wrapper').length},
                dataType: 'json',
                success: function(data){
                    if (data.html && data.visual) {
                        $('body').append(data.html);
                        notificationSelector = $(".notification");
                        notificationSelector.attr("data-name", data.name);

                        switch(parseInt(data.visual)) {
                            case 1:
                                visualisationTypes.showAfterSeconds(parseInt(data.visual_seconds));
                                break;
                            case 2:
                                visualisationTypes.showHideOnScroll();
                                break;
                            case 3:
                                visualisationTypes.showOnMouseLeave();
                                break;
                            case 4:
                                if(data.visual_url.fragment){
                                    visualisationTypes.viewAfterPage(data.visual_url.fragment);
                                }
                                break;
                            default:
                            // code block
                        }
                        if ((page == 'brand_city' || page == 'brand_detail') && data.visual != 3 && !isMobile) {
                            $('.brand-popup, .newsletter-popup').remove();
                        }
                    }

                    notificationEvents();
                    closeNotification(parseInt(data.visual));
                },
                error: function(err){}
            });
        }
    }

    // function setCookie(name, value, days) {
    //     var d = new Date;
    //     d.setTime(d.getTime() + 24*60*60*1000*days);
    //     document.cookie = name + "=" + value + ";path=/;expires=" + d.toUTCString();
    // }

    // function deleteCookie(name) { setCookie(name, '', -1); }
    
    // function delete_cookie( name ) {
    //     document.cookie = name + '=; expires=Thu, 01 Jan 1970 00:00:01 GMT;';
    // }

    function init() {

        if (checkForVideoBanner()) {
            getActiveNotification();
        }
    }

    init();
}

$(document).ready(function() {
    var siteNotifications = new SiteNotifications();
});