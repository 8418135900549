/*
 *  Search Homepage / Search for City /
 */

home_search_hidden = true;

$('.select-hidden-mode .select-options').mCustomScrollbar({
  callbacks:{
      onInit: function(){

        $('.select-hidden-mode .select-search input').hideseek({
      highlight: true,
      list: ".select-hidden-mode .select-options .mCSB_container",
      hidden_mode: true
    });

    home_search_hidden = true;

    $('.select-hidden-mode  .select-search input').on("_after", function() {

      home_search_hidden = true;
      
      var options = $(".select-hidden-mode .select-options .mCSB_container").find(".select-option");

      $.each(options, function(a, b) {
        
        if ($(b).is(":visible")) {
          home_search_hidden = false;
        };
      });

      if (home_search_hidden) {
        $(".select-hidden-mode .select-options").addClass('empty');
      }else{
        $(".select-hidden-mode .select-options").removeClass('empty');
      }
    });

      }
  }
});


/*
 *  Search Hide on Click Out ( click on the body tag )
 */

$('body').on('click', function(e) {
  if (!$(e.target).isWithin(".select-city.select-hidden-mode") ) {
    $(".select-hidden-mode").find('.select-options').addClass('empty');
    $(".select-hidden-mode").find('.mCSB_container').find('.select-option').hide();
    $(".select-hidden-mode").find('input').val("");
  };
});