function activateListExpander($list, maxheight, speed) {

	var _maxHeight = maxheight ? maxheight : 380,
		_speed = speed ? speed : 200,
		_expanded = false;

	var $ul = $list.find('ul'),
		$btn = $list.next('.list-expand');

	var see_more = $btn.text(),
		see_less = $btn.data('text');

	var _expandHeight = $ul.outerHeight(true)+$ul.position().top;

	if (_expandHeight <= _maxHeight) {
		$btn.hide();
	}

	function collapse() {
		$list.animate({ height: _maxHeight }, _speed, function(){ 
			$list.css({ maxHeight: _maxHeight }); 
			$btn.text(see_more);
		});

		_expanded = false;
	}

	function expand() {
		$list.css({maxHeight: 'none', height: _maxHeight })
				.animate({ height: _expandHeight }, _speed);
		$btn.text(see_less);

		_expanded = true;
	}

	$btn.click(function(){
		if (_expanded) {
			
			collapse();

			return;
		}

		expand();
	});
	
}

function activateCitiesList()
{
	/* Cities List */
	$card = $('.business-card');
	$list = $card.find('.strings-list');

	if ($list.length!==0) {
		activateListExpander($list);
	}
}

activateCitiesList();

$(".strings-list span").on('click', function(e){
	var $el = $(this);
	
	$el.parent().find("span").toggleClass("hidden");
	$el.closest('ul').find('li.hidden-city').toggle();
});