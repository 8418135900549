 $(function(){
   $(".see_more__city").on("click",function(){
       var li = $("li.hidden"),
       count = li.length, not_hidden_count, top, i;
       if (count > 8) { count = 7;}
       if (count) {
           top  = $(".see_more__city").offset().top;
           for(i=0;i<=count;i++){
                li.eq(i).removeClass("hidden");
           }
       }
       $('html, body').animate({ scrollTop: top}, "slow");
       if (count < 7) {$(this).hide();}
       not_hidden_count = $("li.li-brochu").not(".hidden").length;
       $(".pagination-info").find("span").text(not_hidden_count);
   }); 
});